import { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Col,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import axios from 'axios';
import {
  constants,
  Utils,
  ManageMqttData,
  AppDefaults,
  roles,
} from '../../helpers';
import { Avatar, AvatarUtility } from '../../components/common';
import { rolesNames } from '../../helpers/roleEnums';
import { ReactComponent as BrandLogoSvg } from '../../assets/images/BrandLogo.svg';
import { ReactComponent as CloudPortalWhiteLogo } from '../../assets/images/CloudPortalWhiteLogo.svg';
import { ReactComponent as CloudPortalBadgeLogo } from '../../assets/images/CloudPortalBadgeLogo.svg';
import { LiaUserCircleSolid, LiaUserCircle } from 'react-icons/lia';
import {
  HiOutlineLogout,
  HiOutlineOfficeBuilding,
  // HiOutlineLockClosed,
} from 'react-icons/hi';
import { PiArrowUpRightBold } from 'react-icons/pi';
import { BsFillGrid3X3GapFill, BsChevronRight } from 'react-icons/bs';
import { ReactComponent as LicensesIcon } from '../../assets/images/licenses_icon.svg';
import { ReactComponent as UsersIcon } from '../../assets/images/users_icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/location_icon.svg';
import { ReactComponent as DeviceIcon } from '../../assets/images/device_icon.svg';

import { useKeycloak } from '@react-keycloak/web';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { usePoliciesStore } from '../../store/policiesStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import DefaultPolicies from '../../store/reducers/DefaultPolicies';
import greaterThanIcon from '../../assets/images/icons/greaterThanIcon.svg';
import headerMenu from '../../assets/images/icons/Menu.svg';
import { IoHelpCircleOutline } from 'react-icons/io5';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { GoBook } from 'react-icons/go';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { PiChatCenteredText } from 'react-icons/pi';

import {
  clearLiveStreams,
  clearMQTTPeerConnections,
  clearRTCPeerConnections,
  clearRemoteStreams,
  clearWSSConnections,
  getMQTTConnection,
  getPlatformInfo,
  setPlatformInfo,
  resetPlatformInfo,
} from '../../store/reducers/StreamingReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAccountId,
  getAllMqttDataFromResponse,
  getAccountId,
  getAllAreas,
  setAllAreas,
  setSubscribeAreasRequest,
  getSubscribeAreasRequest,
  setKeepAliveRequest,
  getKeepAliveRequest,
  setAllMqttDataFromResponse,
} from '../../store/reducers/AccountReducer';
import {
  checkMQTTConnection,
  connectWithMQTT,
  mqttDisconnectRequest,
  mqttPublish,
  mqttSubscribe,
  subscribeWithAccountId,
  subscribeWithOrgIds,
} from '../../utils/connection/mqttConnection';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import { useNotificationStore } from '../../store/CreateNotificationStore';
import { useModuleStore } from '../../store/ModuleStatusStore';
import { ReactComponent as ShockwaveBrandLogoSvg } from '../../assets/images/ShockwaveBrandLogo.svg';
import { useAppsStore } from '../../store/AppsStore';
import TermConditionPolicyModal from './TermConditionPolicyModal';
import { clearInterval, setInterval } from 'worker-timers';
import { app_version } from '../../helpers/commonUtils';
import {
  getCustomerOrgData,
  resetCustomerOrgData,
  setCustomerOrgData,
} from '../../store/OrganizationsStoreIDB';
import {
  getLoggedInUserRole,
  getOrgInfo,
  getSelfAccountData,
  resetSelfAccountData,
} from '../../store/reducers/OrganizationsReducer';
import {
  getSelectedOrganization,
  setSelectedOrganization,
} from '../../store/AccountStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { AiFillInfoCircle } from 'react-icons/ai';
import { FaCircleCheck } from 'react-icons/fa6';
import { IoIosCloseCircle } from 'react-icons/io';
import '../common/HeaderUserProfile.scss';

function Header({
  backgroundColor,
  showNavigation,
  isAccountScreen = false,
  currentDeviceId = false,
  showHeader = true,
}) {
  // const chatbotRef = useRef(null); // Reference for the chatbot
  const [isOpen, setIsOpen] = useState(false);
  const intervalRef = useRef();
  const orgInfo = useSelector(getOrgInfo);
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const setModuleStatus = useModuleStore((state) => state.setModuleStatus);
  setModuleStatus(Utils.getModuleStatus());
  const dispatch = useDispatch();
  // TODO: read these two from the store that has them
  let orgId = searchParams.get('orgId');
  let orgName = searchParams.get('orgName');
  const [url, setUrl] = useState(null);
  const navigate = useNavigate();
  const accountId = useSelector(getAccountId);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const getMQTTConnectionStatus = useSelector(getMQTTConnection);
  const platformDetails = useSelector(getPlatformInfo);
  const getSubscribeAreas = useSelector(getSubscribeAreasRequest);
  const getKeepAlive = useSelector(getKeepAliveRequest);
  const setPingApiCallTime = useLoggedInUserData(
    (state) => state.setPingApiCallTime,
  );
  const [licensesAddResponse, setLicensesAddResponse] = useState(null);

  const [orgDetails, setOrgDetails] = useState();

  const allAreasStore = useSelector(getAllAreas);

  const resetLoggedInUserData = useLoggedInUserData(
    (state) => state.resetLoggedInUserData,
  );
  const resetCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.resetCustomerOrgLocations,
  );
  const loggedInUserRole = useSelector(getLoggedInUserRole);

  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies,
  );
  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies,
  );
  const resetNotificationData = useNotificationStore(
    (state) => state.resetStepData,
  );
  const selfAccountData = useSelector(getSelfAccountData);
  const setLoggedInUserData = useLoggedInUserData(
    (state) => state.setLoggedInUserData,
  );
  const { getState } = devicesMQTTStore;
  const state = getState();
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );
  const resetSelfAccount = useOrganizations((state) => state.resetSelfAccount);
  const getPingApiCallTime = useLoggedInUserData(
    (state) => state.getPingApiCallTime,
  );
  let sessionTimeoutValue =
    loggedInUserData && loggedInUserData?.sessionTimeout
      ? loggedInUserData?.sessionTimeout === -1
        ? AppDefaults.DEFAULT_TIMER_TIME
        : parseInt(loggedInUserData?.sessionTimeout) * 60000
      : AppDefaults.DEFAULT_TIMER_TIME;

  const intervalInMillis = Math.ceil(sessionTimeoutValue / 3);

  let lastActivityTimestamp = new Date().getTime();

  const avatarProps = AvatarUtility(loggedInUserData);

  //   useEffect(() => {
  //     const script = document.createElement('script');
  //     script.id = '__ada';
  //     script.src = 'https://static.ada.support/embed2.js';
  //     script.setAttribute('data-handle', 'hanwha-aigent');
  //     script.async = true;
  //     document.body.appendChild(script);

  //     return () => {
  //         document.body.removeChild(script); // Clean up the script on unmount
  //     };
  // }, [isOpen]);

  const toggleChatbot = () => {
    if (window.adaEmbed) {
      window.adaEmbed.toggle();
    }
  };

  const handleIconClick = () => {
    toggleChatbot();
  };

  useEffect(() => {
    const status = localStorage.getItem('isSession');
    sessionTimeoutValue =
      loggedInUserData && loggedInUserData?.sessionTimeout
        ? loggedInUserData?.sessionTimeout === -1
          ? AppDefaults.DEFAULT_TIMER_TIME
          : parseInt(loggedInUserData?.sessionTimeout) * 60000
        : AppDefaults.DEFAULT_TIMER_TIME;
    const token = localStorage.getItem('vmsAuthToken');
    if (status !== 'undefined' && status === '0') {
      if (token !== 'null' && token !== 'undefined' && token !== '') {
        if (
          !loggedInUserData?.email ||
          (loggedInUserData && loggedInUserData?.sessionTimeout === -1)
        ) {
          return;
        }
        localStorage.setItem('isSession', 1);
        localStorage.setItem('isSession', 1);
        setPingApiCallTime(lastActivityTimestamp);
        setTimeout(() => {
          if (!axios.defaults.headers.common['Authorization']) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          }
          OnIdleTrack(lastActivityTimestamp);
        }, 2000);
      }
    }
  }, [loggedInUserData]);

  const handleMouse = () => {
    const token = localStorage.getItem('vmsAuthToken');
    if (token === 'null' || token === 'undefined' || token === '') {
      return;
    }
    lastActivityTimestamp = new Date().getTime();
    const lastPingDurationInMins =
      (lastActivityTimestamp - getPingApiCallTime()) / 60000;
    if (lastPingDurationInMins >= intervalInMillis / 60000) {
      // Invoke API
      //TODO Delete Later
      console.log('KeepAlive: Handle Mouse ', lastActivityTimestamp);
      setPingApiCallTime(lastActivityTimestamp);
      OnIdleTrack(lastActivityTimestamp);
    }
  };

  useEffect(() => {
    const idPing = setInterval(() => {
      const lastActiveDurationInMins =
        (new Date().getTime() - lastActivityTimestamp) / 1000 / 60;
      // Check if last activity was done in last 10 mins or not
      //TODO DELETE LATER
      console.log('KeepAlive: called => ', lastActiveDurationInMins);
      if (lastActiveDurationInMins <= intervalInMillis / 60000) {
        // Invoke API
        //TODO DELETE LATER
        console.log('KeepAlive: activeTimer => ', lastActivityTimestamp);
        setPingApiCallTime(lastActivityTimestamp);
        OnIdleTrack(lastActivityTimestamp);
      }
    }, intervalInMillis - 60000);
    intervalRef.current = idPing;
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [loggedInUserData]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouse);

    return () => {
      document.removeEventListener('mousemove', handleMouse);
    };
  }, [loggedInUserData]);

  useEffect(() => {
    if (orgDetails) {
      const policies = orgDetails?.policies;
      const updatedPoliciesData = Utils.mapUserPolicies(
        DefaultPolicies,
        policies,
      );
      setCustomerOrgPolicies(updatedPoliciesData);
    }
  }, [orgDetails]);

  const OnIdleTrack = async (timestamp) => {
    const token = localStorage.getItem('vmsAuthToken');
    if (token === 'null' || token === 'undefined' || token === '') {
      return;
    } else if (loggedInUserData && loggedInUserData?.sessionTimeout === -1) {
      return;
    }
    await axios
      .get('partner/ping', {
        params: {
          lastActivityTime: Math.ceil(timestamp / 1000),
        },
        ...Utils.requestHeader(),
      })
      .then((res) => {
        const responseData = res?.data;
        if (responseData?.meta?.code === 401) {
          setPingApiCallTime(0);
          clearInterval(intervalRef?.current);
          invalidateSessionApi();
        }
      });
  };

  const breadList = [
    {
      url: `/locations/manage.html?orgId=${orgId ? orgId : orgInfo?.orgId}`,
      title: constants.ORG_DASHBOARD_LOCATIONS_SECTION_TITLE,
      activeUrl: '/locations/manage.html',
      icon: <LocationIcon></LocationIcon>,
      viewPolicy: getCustomerOrgPolicies()?.view_location,
    },
    {
      url: `/devices/listing.html?orgId=${orgId ? orgId : orgInfo?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
      activeUrl: '/devices/listing.html',
      icon: <DeviceIcon></DeviceIcon>,
      viewPolicy: getCustomerOrgPolicies()?.view_device,
    },
    {
      url: `/users/manage.html?orgId=${orgId ? orgId : orgInfo?.orgId}`,
      title: constants.USERS_PAGE_TITLE,
      activeUrl: '/users/manage.html',
      icon: <UsersIcon></UsersIcon>,
      viewPolicy: getCustomerOrgPolicies()?.view_all_accounts,
    },
    {
      url: `/licenses/listing.html?orgId=${orgId ? orgId : orgInfo?.orgId}`,
      title: constants.LICENSES_PAGE_TITLE,
      activeUrl: '/licenses/listing.html',
      icon: <LicensesIcon></LicensesIcon>,
      viewPolicy: getCustomerOrgPolicies()?.view_license_key,
    },
  ];
  const customersUrl = '/customers/manage.html';
  const managePartnerUrl = '/managepartnerusers.html';
  const getApps = useAppsStore((state) => state.getApps);
  let appList = useAppsStore((state) => state.appList);
  let helpData = useAppsStore((state) => state.helpData);
  const [customerOrgDetails, setCustomerOrgDetails] = useState();
  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustomerOrgDetails(orgs || []);
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000,
  );

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();
    debouncedLoadSelectedOrgData();
    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData, debouncedLoadSelectedOrgData]);

  useEffect(() => {
    const setOrg = async () => {
      if (customerOrgDetails?.length) {
        const userIndex = customerOrgDetails?.find(
          (user) => user.orgId === orgId,
        );
        await setSelectedOrganization(userIndex);
      }
    };
    setOrg();
  }, [customerOrgDetails]);

  useEffect(() => {
    const tokenVms = localStorage.getItem('vmsAuthToken');
    setTimeout(() => {
      if (
        (tokenVms === 'null' || tokenVms === 'undefined') &&
        !isAccountScreen
      ) {
        navigate(`/`);
      }
    }, 3000);

    if (window.performance) {
      if (performance.navigation.type === 1) {
        dispatch(setSubscribeAreasRequest(false));
        dispatch(setKeepAliveRequest(false));
      }
    }
  }, []);

  useEffect(() => {
    if (
      Array.isArray(customerOrgDetails) &&
      customerOrgDetails?.length > 0 &&
      getMQTTConnectionStatus
    ) {
      subscribeWithOrgIds(customerOrgDetails);

      const deviceSubscription = {
        topic: `d/notify/${accountId}/${state.getSessionId()}`,
        qos: 0,
      };

      // Subscribe  to the device topic
      mqttSubscribe(deviceSubscription);
    }
    if (loggedInUserData) {
      subscribeWithAccountId(loggedInUserData?.accountId);
      dispatch(setAccountId(loggedInUserData?.accountId));
    }
  }, [
    getMQTTConnectionStatus,
    loggedInUserData,
    getSubscribeAreas,
    getKeepAlive,
    customerOrgDetails?.length > 0,
  ]);

  const getTopic = (areas) => {
    return areas?.map((area) => {
      return `a/rt-events/${area}`;
    });
  };

  const deleteRequest = (areas) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: getTopic(areas),
      payload: JSON.stringify({
        tid: `${tid}`,
        // to: `${deviceId}`,
        from: `${accountId}`,
        msg: {
          resource: 'camera/events/live-events',
          action: 'delete',
          properties: {
            sessionId: sessionId,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };

    mqttPublish(context);
  };

  //   TODO: Below code is in observation
  useEffect(() => {
    const tokenWeb = localStorage.getItem('vmsAuthToken');
    if (!tokenWeb || tokenWeb === 'undefined') {
      return;
    }
    const isMqttTokenExpired =
      platformDetails?.mqtt?.expiry * 1000 < new Date().getTime();

    if (
      !checkMQTTConnection() &&
      accountId &&
      platformDetails &&
      platformDetails?.mqtt?.token &&
      !isMqttTokenExpired
    ) {
      connectWithMQTT(accountId);
    } else if (
      accountId &&
      ((platformDetails &&
        (isMqttTokenExpired || !platformDetails?.mqtt?.token)) ||
        !platformDetails)
    ) {
      getLatestPlatformData();
    }
  }, [accountId, checkMQTTConnection(), platformDetails]);

  const getLatestPlatformData = () => {
    axios
      .get(`/partner/platform`, {
        withCredentials: true,
        ...Utils.requestHeader(),
      })
      .then((response) => {
        if (response?.data?.data) {
          dispatch(setPlatformInfo(response?.data?.data));
        }
      });
  };

  useEffect(() => {
    let requestUrl = location.pathname.replace(/\/+$/, '');
    setUrl(requestUrl);
  }, [location]);

  useEffect(() => {
    if (selfAccountData?.orgType) {
      setLoggedInUserData(selfAccountData);
    }
  }, [selfAccountData]);

  const getUrl = (url) => {
    return url?.replace(/\/[^/]+\.html$/, '');
  };

  //=== Update the device data based on MQTT response data
  useEffect(() => {
    const handleMqttData = async () => {
      let modalStatus = parseInt(localStorage.getItem('modalOpen'));
      if (allMqttData !== null && modalStatus !== 1) {
        //=== Update Customer Org Page Devices Data
        if (customerOrgDetails?.length > 0) {
          let updateCustData =
            ManageMqttData.updateCustomerOrgDevicesDataByMqtt(
              customerOrgDetails,
              allMqttData,
            );
          await setCustomerOrgData(updateCustData);
        }
        //=== Update Devices Data
        ManageMqttData.updateDeviceDataByMqtt(allMqttData, currentDeviceId);
      }
    };
    handleMqttData();
    if (allMqttData?.msg?.resource?.includes('order/')) {
      setLicensesAddResponse(allMqttData?.msg?.properties);
      dispatch(setAllMqttDataFromResponse(null));
    }
  }, [allMqttData]);

  const invalidateSessionApi = () => {
    const reqBody = {
      refresh_token: keycloak?.refreshToken,
    };
    axios
      .post('/user/token/invalidate/app', reqBody, Utils.requestHeader())
      .then(() => {
        handleSiteLogout();
      });
  };

  /* On click submit button - send request to approve the customer accounts API */
  const handleSiteLogout = async () => {
    dispatch(clearWSSConnections());
    dispatch(clearRTCPeerConnections());
    dispatch(clearLiveStreams());
    dispatch(clearMQTTPeerConnections());
    dispatch(clearRemoteStreams());
    dispatch(resetPlatformInfo());
    localStorage.setItem('authenticated', false);
    localStorage.setItem('vmsAuthToken', null);
    localStorage.removeItem('tokenSend');
    localStorage.setItem('isSessionApiCall', false);
    localStorage.setItem('isAmplitudeSingleCall', false);
    await resetCustomerOrgData();
    resetNotificationData();
    resetCustomerOrgLocations();
    resetSelfAccount();
    keycloak.logout({
      redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
    });
    dispatch(resetSelfAccountData());
    resetLoggedInUserData();
    setPingApiCallTime(0);
    if (allAreasStore) {
      deleteRequest(allAreasStore);
      dispatch(setAllAreas([]));
    }

    dispatch(setSubscribeAreasRequest(false));
    dispatch(setKeepAliveRequest(false));
    mqttDisconnectRequest();
  };

  const isAppsIcon = () => {
    if (
      url !== '/customers/manage.html' &&
      url !== managePartnerUrl &&
      url !== '/organizationsettings.html' &&
      (loggedInUserData?.orgType === 'CUSTOMER_ORG' || orgId)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const createSession = async (redirectUrl) => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    const expireIn = keycloak?.tokenParsed?.exp - currentTime;
    const refreshExpireIn = keycloak?.refreshTokenParsed?.exp - currentTime;
    const reqBody = {
      expires_in: expireIn,
      refresh_expires_in: refreshExpireIn,
      refresh_token: keycloak?.refreshToken,
    };
    const config = {
      credentials: 'include',
      withCredentials: true,
      ...Utils.requestHeader(),
    };

    const res = await axios.post('user/session', reqBody, config);

    if (res.status === 200) {
      window.open(redirectUrl, '_blank');
    }
  };

  const handleNavigation = async (appData) => {
    if (appData) {
      let redirectUrl = '';

      if (
        appData.id === constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD &&
        (loggedInUserRole === roles.ROLE1PORTAL ||
          loggedInUserRole === roles.ROLE4PORTAL ||
          loggedInUserRole === roles.ROLE5PORTAL ||
          loggedInUserRole === roles.ROLE6PORTAL)
      ) {
        redirectUrl = `${appData?.url}/customers/dashboard.html?orgId=${
          orgId ? orgId : orgDetails?.orgId
        }&orgName=${orgName ? orgName : orgDetails?.orgName}`;
      } else {
        redirectUrl = `${appData?.url}?orgId=${
          orgId ? orgId : orgDetails?.orgId
        }`;
      }

      await createSession(redirectUrl);
    }
  };

  return (
    <>
      {showHeader && (
        <div className="position-sticky top-0" style={{ zIndex: 1020 }}>
          <TermConditionPolicyModal callback={handleSiteLogout} />
          <Navbar
            collapseOnSelect
            sticky="top"
            expand="lg"
            className="site-top-bar px-md-3 px-2 px-lg-5"
            style={{ backgroundColor: backgroundColor }}
          >
            <Container fluid>
              <Navbar.Brand>
                {process.env.REACT_APP_PROJECT ===
                AppDefaults.PROJECT_MEGATRON ? (
                  <>
                    <ShockwaveBrandLogoSvg />{' '}
                  </>
                ) : (
                  <BrandLogoSvg />
                )}
              </Navbar.Brand>
              {!showNavigation && (
                <>
                  <Nav className="me-auto site-start-links d-md-flex coud-portal-nav-title">
                    <span className="ms-2 me-1 text-white-50"> | </span>
                    <Nav.Link
                      onClick={() => {
                        if (loggedInUserData?.orgType === 'INSTALLER_ORG') {
                          navigate(customersUrl);
                        } else {
                          if (loggedInUserData?.accountStatus !== 'INACTIVE') {
                            navigate(
                              `/landing-page.html?orgId=${customerOrgDetails?.[0]?.orgId}&orgName=${customerOrgDetails?.[0]?.orgName}&fromPush=true`,
                            );
                          }
                        }
                      }}
                      className={`left-link align-self-center cloud-portal-style ${
                        loggedInUserData?.accountStatus === 'INACTIVE' &&
                        'inactive-user'
                      }`}
                      disabled={
                        !customerOrgDetails?.[0]?.orgId &&
                        loggedInUserData?.orgType === 'CUSTOMER_ORG'
                      }
                    >
                      <CloudPortalWhiteLogo />
                    </Nav.Link>
                  </Nav>
                  <Nav className="site-end-links mobile">
                    <NavDropdown
                      className="profile-nav user-nav-profile right-link"
                      title={
                        <div className="profile-nav-icon">
                          <img alt="" src={headerMenu} />
                        </div>
                      }
                      id="collasible-nav-dropdown"
                    >
                      <div className="profile-header">
                        <div className="avatar-container">
                          <Avatar
                            valueType={avatarProps.valueType}
                            value={avatarProps.value}
                            size="extraLarge"
                            avatarStyle="roundedCircle"
                          />
                        </div>

                        <h2 className="profile-name">
                          {loggedInUserData?.firstName}{' '}
                          {loggedInUserData?.lastName}
                        </h2>
                        <p className="profile-email">{`${
                          loggedInUserData?.email ? loggedInUserData?.email : ''
                        }`}</p>
                        <div className="badge-container">
                          <span className="admin-badge">
                            <span className="badge-icon">
                              <CloudPortalBadgeLogo />
                            </span>
                            {rolesNames[loggedInUserData.role]}
                          </span>
                        </div>
                      </div>
                      <div className="profile-menu">
                        <NavDropdown.Item
                          className={`menu-item ${
                            location.pathname.startsWith('/accountsettings')
                              ? 'active'
                              : ''
                          } ${
                            loggedInUserData?.accountStatus === 'INACTIVE'
                              ? 'inactive-user-setting'
                              : ''
                          }`}
                          onClick={() => {
                            if (
                              loggedInUserData?.accountStatus !== 'INACTIVE'
                            ) {
                              navigate('/accountsettings.html');
                            }
                          }}
                        >
                          <span className="menu-icon">
                            <LiaUserCircle size={24} />
                          </span>
                          <span className="menu-text">
                            {constants.TOP_HEADER_ACCOUNT_SETTINGS_NAV_TITLE}
                          </span>
                          <span className="menu-arrow">
                            <PiArrowUpRightBold size={20} />
                          </span>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          className={`menu-item ${
                            location.pathname.startsWith(
                              '/organizationsettings',
                            ) ||
                            location.pathname.startsWith('/editOrganization')
                              ? 'active'
                              : ''
                          } ${
                            loggedInUserData?.accountStatus === 'INACTIVE'
                              ? 'inactive-user-setting'
                              : ''
                          }`}
                          onClick={() => {
                            if (
                              loggedInUserData?.accountStatus !== 'INACTIVE'
                            ) {
                              if (
                                loggedInUserData?.orgType === 'CUSTOMER_ORG'
                              ) {
                                navigate(
                                  `/editOrganization.html?orgId=${loggedInUserData?.orgId}`,
                                );
                              } else {
                                navigate('/organizationsettings.html');
                              }
                            }
                          }}
                        >
                          <span className="menu-icon">
                            <HiOutlineOfficeBuilding size={24} />
                          </span>
                          <span className="menu-text">
                            {constants.TOP_HEADER_ORG_SETTINGS_NAV_TITLE}
                          </span>
                          <span className="menu-arrow">
                            <PiArrowUpRightBold size={20} />
                          </span>
                        </NavDropdown.Item>

                        <div className="menu-separator"></div>

                        <NavDropdown.Item
                          className="menu-item"
                          onClick={invalidateSessionApi}
                        >
                          <span className="menu-icon">
                            <HiOutlineLogout size={24} />
                          </span>
                          <span className="menu-text">
                            {constants.TOP_HEADER_LOGOUT_SETTINGS_NAV_TITLE}
                          </span>
                        </NavDropdown.Item>
                        <div className="menu-separator"></div>

                        <p className="version-info fs-6 text-center">
                          <span>
                            {constants.RELEASE_VERSION_TITLE}
                          </span>
                          <span>{app_version}</span>
                        </p>
                      </div>
                    </NavDropdown>
                  </Nav>
                  <Nav className="site-end-links desktop-header">
                    {loggedInUserData?.orgType === 'INSTALLER_ORG' &&
                      loggedInUserData?.accountStatus !==
                        constants.INACTIVE_USER_STATUS_LABEL.toUpperCase() &&
                      getLoggedInUserPolicies()?.view_other_account && (
                        <Nav.Link
                          onClick={() => {
                            navigate(managePartnerUrl);
                          }}
                          className={`right-link align-self-center ${
                            loggedInUserData?.accountStatus === 'INACTIVE' &&
                            'inactive-user'
                          }`}
                        >
                          <div className="fs-6">
                            <span className="d-none d-md-inline-block">
                              {constants.TOP_HEADER_PARTNER_USERS_NAV_TITLE}
                            </span>
                            <span className="d-inline-block d-md-none">
                              {constants.TOP_HEADER_PARTNER_USERS_NAV_TITLE}
                            </span>
                            <span className="ps-3 d-md-inline-block">
                              <BsChevronRight
                                style={{ height: 14, width: 14 }}
                              />
                            </span>
                          </div>
                        </Nav.Link>
                      )}
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={
                        <Popover id="help-icon-link-section">
                          <Popover.Body>
                            <div className="top-container">
                              <NavDropdown.Item
                                className="guide-links"
                                onClick={() => {
                                  window.open(
                                    process.env.REACT_APP_USER_GUIDE_URL,
                                    '_blank',
                                  );
                                }}
                              >
                                <div className="guide-titles">
                                  <GoBook size={24} color="#000000C4" />
                                  {
                                    constants.TOP_HEADER_HELP_USER_GUIDE_NAV_TITLE
                                  }
                                </div>
                                <PiArrowUpRightBold color="#747E87" size={20} />
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                onClick={handleIconClick}
                                className="guide-links"
                              >
                                <div className="guide-titles">
                                  <PiChatCenteredText
                                    on
                                    size={24}
                                    color="#000000C4"
                                  />
                                  {constants.TOP_HEADER_HELP_CHAT_NAV_TITLE}
                                </div>
                                <PiArrowUpRightBold color="#747E87" size={20} />
                              </NavDropdown.Item>
                            </div>
                            <div className="devider"></div>
                            <div className="bottom-container">
                              <NavDropdown.Item className="guide-links">
                                <div className="guide-titles">
                                  <AiOutlineInfoCircle
                                    size={24}
                                    color="#000000C4"
                                  />
                                  {constants.TOP_HEADER_HELP_ABOUT_NAV_TITLE}
                                </div>
                              </NavDropdown.Item>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                      rootClose={true}
                      className="help-icon-placement"
                      noArrow
                    >
                      <Nav.Link className={`align-self-center d-block`}>
                        <div className="help-icon-container">
                          <IoIosHelpCircleOutline size={24} color="#DFE1E2" />
                        </div>
                      </Nav.Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Body className="px-0">
                            <div className="top-container">
                              {appList?.map((item, index) => (
                                <Col
                                  key={`${index}-${item.name}`}
                                  xs={4}
                                  className="text-center app-list-style"
                                >
                                  <NavDropdown.Item
                                    onClick={() => handleNavigation(item)}
                                  >
                                    <div className="app-style">
                                      <img
                                        className="appIconImgHeight"
                                        src={item.wicon}
                                        alt="appIconImgHeight"
                                      />
                                    </div>
                                    <div className="appsMenuNameStyle">
                                      <span>{item.name}</span>
                                    </div>
                                  </NavDropdown.Item>
                                </Col>
                              ))}
                            </div>
                            <div className="bottom-container">
                              <NavDropdown.Item
                                onClick={() => {
                                  window.open(helpData?.portalCommon, '_blank');
                                }}
                              >
                                <div className="inner-container">
                                  <IoHelpCircleOutline
                                    size={24}
                                    color="#000000C4"
                                  />

                                  <span className="w-100">
                                    {constants.HELP}
                                  </span>
                                  <img
                                    src={greaterThanIcon}
                                    alt=""
                                    width={'24px'}
                                    height={'24px'}
                                  />
                                </div>
                              </NavDropdown.Item>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                      rootClose={true}
                      className="profile-nav right-link"
                      noArrow
                    >
                      <Nav.Link
                        onClick={() => getApps(`partner/apps`)}
                        className={`profile-nav-icon align-self-center ${
                          isAppsIcon() ? 'd-block' : 'd-none'
                        }`}
                      >
                        {loggedInUserData?.accountStatus !== 'INACTIVE' && (
                          <BsFillGrid3X3GapFill />
                        )}
                      </Nav.Link>
                    </OverlayTrigger>
                    {getCustomerOrgPolicies()?.view_cust_org_dashboard &&
                      (loggedInUserData?.orgType === 'CUSTOMER_ORG' ||
                        orgInfo?.orgId) && (
                        <Nav.Link
                          onClick={() => {
                            navigate(
                              `/customers/dashboard.html?orgId=${orgInfo?.orgId}`,
                            );
                          }}
                          className={`profile-nav-icon align-self-center ${
                            loggedInUserData?.accountStatus === 'INACTIVE' &&
                            'inactive-user'
                          } ${isAppsIcon() ? 'd-block' : 'd-none'}`}
                        >
                          <div className={`profile-nav-icon`}>
                            <HiOutlineOfficeBuilding size={20} />
                          </div>
                        </Nav.Link>
                      )}
                    <NavDropdown
                      className="profile-nav user-nav-profile right-link"
                      title={
                        <div className="profile-nav-icon">
                          <LiaUserCircleSolid size={30} />
                        </div>
                      }
                      id="collasible-nav-dropdown"
                    >
                      <div className="profile-header">
                        <div className="avatar-container">
                          <Avatar
                            valueType={avatarProps.valueType}
                            value={avatarProps.value}
                            size="extraLarge"
                            avatarStyle="roundedCircle"
                          />
                        </div>

                        <h2 className="profile-name">
                          {loggedInUserData?.firstName}{' '}
                          {loggedInUserData?.lastName}
                        </h2>
                        <p className="profile-email">{`${
                          loggedInUserData?.email ? loggedInUserData?.email : ''
                        }`}</p>
                        <div className="badge-container">
                          <span className="admin-badge">
                            <span className="badge-icon">
                              <CloudPortalBadgeLogo />
                            </span>{' '}
                            {rolesNames[loggedInUserData.role]}
                          </span>
                        </div>
                      </div>
                      <div className="profile-menu">
                        <NavDropdown.Item
                          className={`menu-item ${
                            location.pathname.startsWith('/accountsettings')
                              ? 'active'
                              : ''
                          } ${
                            loggedInUserData?.accountStatus === 'INACTIVE'
                              ? 'inactive-user-setting'
                              : ''
                          }`}
                          onClick={() => {
                            if (
                              loggedInUserData?.accountStatus !== 'INACTIVE'
                            ) {
                              navigate('/accountsettings.html');
                            }
                          }}
                        >
                          <span className="menu-icon">
                            <LiaUserCircle size={24} />
                          </span>
                          <span className="menu-text">
                            {constants.TOP_HEADER_ACCOUNT_SETTINGS_NAV_TITLE}
                          </span>
                          <span className="menu-arrow">
                            <PiArrowUpRightBold size={20} />
                          </span>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          className={`menu-item ${
                            location.pathname.startsWith(
                              '/organizationsettings',
                            ) ||
                            location.pathname.startsWith('/editOrganization')
                              ? 'active'
                              : ''
                          } ${
                            loggedInUserData?.accountStatus === 'INACTIVE'
                              ? 'inactive-user-setting'
                              : ''
                          }`}
                          onClick={() => {
                            if (
                              loggedInUserData?.accountStatus !== 'INACTIVE'
                            ) {
                              if (
                                loggedInUserData?.orgType === 'CUSTOMER_ORG'
                              ) {
                                navigate(
                                  `/editOrganization.html?orgId=${loggedInUserData?.orgId}`,
                                );
                              } else {
                                navigate('/organizationsettings.html');
                              }
                            }
                          }}
                        >
                          <span className="menu-icon">
                            <HiOutlineOfficeBuilding size={24} />
                          </span>
                          <span className="menu-text">
                            {constants.TOP_HEADER_ORG_SETTINGS_NAV_TITLE}
                          </span>
                          <span className="menu-arrow">
                            <PiArrowUpRightBold size={20} />
                          </span>
                        </NavDropdown.Item>

                        <div className="menu-separator"></div>

                        <NavDropdown.Item
                          className="menu-item"
                          onClick={invalidateSessionApi}
                        >
                          <span className="menu-icon">
                            <HiOutlineLogout size={24} />
                          </span>
                          <span className="menu-text">
                            {constants.TOP_HEADER_LOGOUT_SETTINGS_NAV_TITLE}
                          </span>
                        </NavDropdown.Item>

                        <div className="menu-separator"></div>

                        <p className="version-info fs-6 text-center">
                          <span>
                            {constants.RELEASE_VERSION_TITLE}
                          </span>
                          <span>{app_version}</span>
                        </p>
                      </div>
                    </NavDropdown>
                  </Nav>
                </>
              )}
            </Container>
          </Navbar>
          {!(
            (url === '/organizationsettings.html' ||
              url === '/updatepassword.html' ||
              url === '/accountsettings.html') &&
            loggedInUserData?.orgType === 'INSTALLER_ORG'
          ) &&
            url !== '/customers/dashboard.html' &&
            url !== '/managetechnicians.html' &&
            !isAccountScreen && (
              <Navbar expand="lg" className="sub-site-top-bar">
                {loggedInUserData?.orgType === 'INSTALLER_ORG' &&
                (url === '/customers/manage.html' ||
                  url === managePartnerUrl) ? (
                  <div className="container h-100 align-items-nav-header">
                    <div className="tab-btns" role="tablist">
                      {url === managePartnerUrl ? (
                        <button
                          className={`btn px-4 ms-0 ms-md-3 px-xl-5 tab-btn ${
                            url === managePartnerUrl && 'active'
                          }`}
                          data-target="tab-content-1"
                          onClick={() => navigate(managePartnerUrl)}
                        >
                          {' '}
                          {constants.TOP_HEADER_PARTNER_USERS_NAV_TITLE}
                        </button>
                      ) : (
                        <button
                          className={`btn ms-0 ms-md-3 px-4 px-xl-5 tab-btn ${
                            url === customersUrl && 'active'
                          }`}
                          data-target="tab-content-1"
                          onClick={() => navigate(customersUrl)}
                        >
                          {constants.TOP_HEADER_CUSTOMERS_NAV_TITLE}
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="container h-100 justify-content-center">
                    <div
                      className={`tab-btns ${
                        loggedInUserData &&
                        loggedInUserData?.accountStatus === 'INACTIVE' &&
                        'inactive-user'
                      }`}
                      role="tablist"
                    >
                      {breadList.map((item, index) => (
                        <Fragment key={`${index}-${item.title}`}>
                          {item.viewPolicy ? (
                            <button
                              key={`${index}-${item.title}`}
                              className={`btn px-4  px-xl-5 tab-btn ${
                                (url === item.activeUrl ||
                                  getUrl(item.activeUrl) === getUrl(url)) &&
                                'active'
                              }`}
                              data-target="tab-content-1"
                              onClick={() =>
                                loggedInUserData &&
                                loggedInUserData?.accountStatus !==
                                  'INACTIVE' &&
                                navigate(item.url)
                              }
                              disabled={!orgId && !orgInfo?.orgId}
                            >
                              <span
                                className={`d-inline-block pe-2 ${
                                  item.title ===
                                    constants.ORG_DASHBOARD_LOCATIONS_SECTION_TITLE &&
                                  ''
                                }`}
                              >
                                {item.icon}
                              </span>
                              <span className="d-none d-md-inline-block">
                                {item.title}
                              </span>
                            </button>
                          ) : null}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                )}
              </Navbar>
            )}
        </div>
      )}
      <div className="licenses-status-toast">
        <ToastContainer position={'top-end'}>
          <Toast
            autohide={true}
            show={licensesAddResponse}
            delay={3000}
            onClose={() => setLicensesAddResponse(null)}
          >
            <Toast.Body>
              {licensesAddResponse && licensesAddResponse?.progress ? (
                <div className="d-flex">
                  <AiFillInfoCircle color="#4891FF" />
                  <div>{constants.LICENSE_ADD_STATUS_START}</div>
                </div>
              ) : licensesAddResponse && licensesAddResponse?.success ? (
                <div className="d-flex">
                  <FaCircleCheck color="#00B960" />
                  <div>{constants.LICENSE_ADD_STATUS_SUCCESS}</div>
                </div>
              ) : licensesAddResponse &&
                licensesAddResponse?.success === false ? (
                <div className="d-flex">
                  <IoIosCloseCircle color="#FA4D4D" />
                  <div>{constants.LICENSE_ADD_STATUS_FAIL}</div>
                </div>
              ) : null}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    </>
  );
}

Header.defaultProps = {
  backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
    '--brand_black',
  ),
};

export default Header;
