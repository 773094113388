import './TableTooltip.scss';
import { useEffect, useRef, useState } from 'react';

const Tooltip = ({ children, text, Component }) => {
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const { height } = ref.current.getBoundingClientRect();

    setPosition({ top: height + 6, left: 0 });
  }, []);

  return (
    <span
      ref={ref}
      className="table-tooltip-container"
      onMouseOver={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div
          className="hv-tooltip"
          style={{ top: position.top, left: position.left }}
        >
          {Component ? Component : text}
        </div>
      )}
    </span>
  );
};

export default Tooltip;
