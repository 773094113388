import {
  Container,
  Row,
  Tabs,
  Tab,
  ToastContainer,
  Toast,
} from 'react-bootstrap';
import { Header, BreadcrumbList } from '../../components/common';
import { constants, Utils } from '../../helpers';
import PageWrapper from '../PageWrapper';
import { getOrgInfo } from '../../store/reducers/OrganizationsReducer';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import './Settings.scss';
import AccountSettings from './AccountSettings';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { FaCircleCheck } from 'react-icons/fa6';
import { IoIosCloseCircle } from 'react-icons/io';
import UpdatePassword from './UpdatePassword';

function AccountSettingsTabs() {
  const [activeTab, setActiveTab] = useState('account-setting');
  const orgInfo = useSelector(getOrgInfo);
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const [showToast, setShowToast] = useState(false);

  const isOrgInfoValid = orgInfo?.orgId && orgInfo?.orgName;
  const breadList = !isOrgInfoValid
    ? [
        {
          url: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)
            ?.url,
          title: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)
            ?.title,
        },
        {
          url: `/accountsettings.html`,
          title: constants.ACCOUNT_SETTINGS_PAGE_TITLE,
        },
      ]
    : [
        {
          url: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)
            ?.url,
          title: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)
            ?.title,
        },
        {
          url: `/landing-page.html?orgId=${orgInfo?.orgId}&orgName=${orgInfo?.orgName}&fromPush=true`,
          title: orgInfo?.orgName,
        },
        {
          url: `/accountsettings.html`,
          title: constants.ACCOUNT_SETTINGS_PAGE_TITLE,
        },
      ];

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  const handleToast = (message, variant) => {
    setToastMessage(message);
    setToastVariant(variant);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };
  const handleNavigation = () => {
    if (isOrgInfoValid) {
      navigate(
        `/landing-page.html?orgId=${orgInfo?.orgId}&orgName=${orgInfo?.orgName}&fromPush=true`,
      );
    } else {
      navigate('/customers/manage.html');
    }
  };
  return (
    <div className="App account-settings">
      <Header />
      <ToastContainer className="custom-toast-container">
        <Toast
          autohide
          show={showToast}
          delay={3000}
          onClose={() => setShowToast(false)}
          className={`custom-toast`}
        >
          <div className="d-flex align-items-center gap-3">
            <div className="icon">
              {toastVariant === 'success' && <FaCircleCheck color="#00B960" />}
              {toastVariant === 'danger' && (
                <IoIosCloseCircle color="#FA4D4D" />
              )}
            </div>
            <div className="message">{toastMessage}</div>
          </div>
        </Toast>
      </ToastContainer>

      <PageWrapper className="mb-5">
        <div className="page-header mb-5">
          <Container className="mw-100">
            <div className="breadcrumb-container">
              <div className="breadcrumb-wrap">
                <Row className="g-4">
                  <div className="page-title text-start">
                    {constants.ACCOUNT_SETTINGS_PAGE_TITLE}
                  </div>
                </Row>
                <BreadcrumbList
                  list={Utils.CheckBreadcrumbForUserRole(breadList)}
                />
              </div>
              <div className="setting-close" onClick={handleNavigation}>
                <IoMdClose />
              </div>
            </div>
            <Tabs
              activeKey={activeTab}
              onSelect={handleTabSelect}
              transition={false}
              id="noanim-tab-example"
              className="license-tab mb-3 justify-content-start"
            >
              <Tab
                eventKey="account-setting"
                title={constants.ORGANIZATION_PROFILE}
              >
                {activeTab === 'account-setting' && (
                  <AccountSettings onResponse={handleToast} />
                )}
              </Tab>
              <Tab
                eventKey="update-password"
                title={constants.ORGANIZATION_PASSWORD}
              >
                {activeTab === 'update-password' && (
                  <UpdatePassword onResponse={handleToast} />
                )}
              </Tab>
            </Tabs>
          </Container>
        </div>
      </PageWrapper>
    </div>
  );
}

export default AccountSettingsTabs;
