import { Outlet } from 'react-router-dom';
import Device from './listDevice/Device';

const Devices = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { Devices, Device };
