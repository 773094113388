import { useState, useEffect, useCallback, useMemo } from 'react';
import FormSteps from './FormSteps';
import DetailsContextProvider from './DetailsContextProvider';
import DataContextProvider from './DataContextProvider';
import { SiteModal } from '../../../components/common/';
import { constants } from '../../../helpers';
import { usePoliciesStore } from '../../../store/policiesStore';
import { GoPlus } from 'react-icons/go';

const AddLicense = (props) => {
  const { isOpenMobilePopup, handleOpenMobilePopup, reloadData } = props;
  const setShowClaimLicenseModal = useCallback(
    () => props.setShowClaimLicenseModal,
    [props],
  );
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [buttonClickValue, setButtonClickValue] = useState(null);
  const [licensesAdded, setLicensesAdded] = useState(false);
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );

  const handleButtonClick = useCallback((value) => {
    setButtonClickValue(value);
  }, []);

  const handleShow = useCallback(
    (flow) => {
      setShowAddOrgModal(true);
      handleButtonClick(flow);
    },
    [handleButtonClick],
  );

  const handleClose = (event) => {
    if (licensesAdded === true) {
      reloadData && reloadData();
      setLicensesAdded(false);
    }
    setShowAddOrgModal(false);
    setShowClaimLicenseModal && setShowClaimLicenseModal(false);
    handleOpenMobilePopup(false);
  };

  useEffect(() => {
    if (props.showClaimLicenseModal) {
      setShowAddOrgModal(true);
    }
  }, [props.showClaimLicenseModal]);

  const LicenseModal = useMemo(
    () => (
      <SiteModal
        modalHeader={true}
        showModal={isOpenMobilePopup || showAddOrgModal}
        hideModal={() => {
          handleClose();
        }}
      >
        <FormSteps
          buttonClickValue={buttonClickValue}
          hideModal={handleClose}
          setLicensesAdded={(e) => {
            setLicensesAdded(e);
          }}
        />
      </SiteModal>
    ),
    [isOpenMobilePopup, showAddOrgModal, buttonClickValue, handleClose],
  );

  return (
    <DetailsContextProvider>
      <DataContextProvider>
        {getCustomerOrgPolicies()?.add_license_customer_org && (
          <div
            className="reload-filter-edit"
            role="button"
            onClick={() => handleShow('Flow1')}
          >
            <GoPlus className="search-icon" size={24} role="button" />
            {constants.LICENSES_HEADER_BUTTON_ADD}
          </div>
        )}
        {LicenseModal}
      </DataContextProvider>
    </DetailsContextProvider>
  );
};

export default AddLicense;
