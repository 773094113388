import { constants } from '../../helpers/';
import SiteModal from '../../components/common/SiteModal';
import { Avatar } from '../../components/common/';
import './OrganizationDetails.scss';

type TechnicianItem = {
  accountId: string;
  accountLanguage: string;
  accountStatus: string;
  activeDate: number;
  createdDate: number;
  disableDate: number;
  email: string;
  firstName: string;
  image: { url: string; expiry: number };
  isLocked: boolean;
  lastName: string;
  name: string;
  phoneNumber: string;
  role: string;
  sessionTimeout: number;
  updatedDate: number;
};

// from AvatarList component
const DEFAULT_AVATAR_LIST_PROPS = {
  avatarSize: 'medium',
  avatarStyle: 'roundedCircle',
};

const TechnicianViewAllModal = ({
  showModal,
  hideModal,
  technicianList,
}: {
  showModal: boolean;
  hideModal: () => void;
  technicianList: TechnicianItem[];
}) => {
  if (!technicianList) return null;

  return (
    <SiteModal
      modalTitle={constants.ASSIGN_TECHNICIANS_LIST_MODAL_TITLE}
      showModal={showModal}
      hideModal={hideModal}
      classes={'technician-view-all-modal'}
      errorMsg={'Error'}
      wrapperClass={''}
      size={647}
    >
      <div className="technician-modal-area">
        <ul className="technician-list">
          {technicianList.map((user) => {
            let profileImg = user?.image?.url;
            let firstName = user?.firstName?.charAt(0).toUpperCase();
            let lastName = user?.lastName?.charAt(0).toUpperCase();
            let letter = firstName + lastName;
            return (
              <li className="technician-list-item" key={user?.accountId}>
                <span className="technician-img-area">
                  <Avatar
                    valueType={profileImg ? 'icon' : 'text'}
                    value={profileImg ? profileImg : letter}
                    size={DEFAULT_AVATAR_LIST_PROPS.avatarSize}
                    avatarStyle={DEFAULT_AVATAR_LIST_PROPS.avatarStyle}
                  />
                </span>
                <span className="technician-name">{user?.name}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </SiteModal>
  );
};

export default TechnicianViewAllModal;
