import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../../components/common';
import { RxCross1 } from 'react-icons/rx';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { SelectField, TextField } from '../../components/forms';
import { Utils, constants } from '../../helpers';
import { Col } from 'react-bootstrap';
import { warningCircle } from '../../assets/images';
import axios from 'axios';

const LdapSettings = ({ orgId, ...props }) => {
  const validate = Yup.object({
    connectionUrl: Yup.string(),
    bindDn: Yup.string(),
    bindCredential: Yup.string(),
    usersDn: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string(),
    phoneNumber: Yup.string(),
    profilePicture: Yup.string(),
  });

  const [savedSettings, setSavedSettings] = useState(false);
  const [connectionInfo, setConnectionInfo] = useState({});
  const [connectionAttributes, setConnectionAttributes] = useState([]);
  const [updatedSettings, setUpdatedSettings] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`/partner/idp/orgs/${orgId}/ldap`, Utils.requestHeader())
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setConnectionInfo(responseData);
            if (responseData) {
              let request = {
                connectionUrl: responseData.connectionUrl,
                bindDn: responseData.bindDn,
                bindCredential: responseData.bindCredential,
                usersDn: responseData.usersDn,
              };
              axios
                .post(
                  `/partner/ldap/orgs/${orgId}/attributes`,
                  request,
                  Utils.requestHeader(),
                )
                .then((res) => {
                  let response = res.data;
                  if (response?.meta?.code === 200) {
                    let options = [];
                    response?.data?.attributes?.forEach((element) => {
                      let obj = {};
                      obj['label'] = element;
                      obj['value'] = element;
                      options.push(obj);
                    });
                    setConnectionAttributes(options);
                  }
                })
                .catch(function (error) {});
            }
          } else {
            setConnectionInfo({});
          }
        }
      })
      .catch(function (error) {});
  }, []);

  const handleClose = () => {
    setSavedSettings(false);
    props.handleClose(false);
    setUpdatedSettings({});
  };

  return (
    <div className="ctn-box innner">
      <div id={'headerText'} className="header-view align-items-start pt-3">
        {!savedSettings ? (
          <div className="titlename-header">
            {' '}
            {constants.ORGANIZATION_LDAP_SETTINGS}{' '}
          </div>
        ) : (
          <div className="success-container w-100">
            <div className="success-title">
              {constants.ORGANIZATION_LDAP_SETTINGS_UPDATE1}
            </div>
            <div className="success-subtitle">
              {constants.ORGANIZATION_LDAP_SETTINGS_UPDATE2}
            </div>
          </div>
        )}
        <RxCross1 className="site-modal-close" onClick={() => handleClose()} />
      </div>
      {!savedSettings ? (
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              connectionUrl: connectionInfo.connectionUrl
                ? connectionInfo.connectionUrl
                : '',
              bindDn: connectionInfo.bindDn ? connectionInfo.bindDn : '',
              bindCredential: connectionInfo.bindCredential
                ? connectionInfo.bindCredential
                : '',
              usersDn: connectionInfo.usersDn ? connectionInfo.usersDn : '',
              firstName: connectionInfo.firstName
                ? connectionInfo.firstName
                : '',
              lastName: connectionInfo.lastName ? connectionInfo.lastName : '',
              email: connectionInfo.email ? connectionInfo.email : '',
              phoneNumber: connectionInfo.phoneNumber
                ? connectionInfo.phoneNumber
                : '',
              profilePicture: connectionInfo.profilePicture
                ? connectionInfo.profilePicture
                : '',
            }}
            validationSchema={validate}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setSavedSettings(true);
              setUpdatedSettings(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, isSubmitting, isValid, dirty }) => (
              <Form className="modal-form" onSubmit={handleSubmit}>
                <div className="titlename mt-3">
                  {constants.ORGANIZATION_LDAP_CONNECTION_URL}
                </div>
                <div>
                  <div className="setting-label">
                    {constants.ORGANIZATION_LDAP_SETTINGS_URL}
                  </div>
                  <TextField
                    placeholder={constants.ORGANIZATION_LDAP_SETTINGS_URL}
                    required={true}
                    name="connectionUrl"
                    type="text"
                    value={values.connectionUrl}
                    disabled={true}
                  />
                </div>
                <div>
                  <div className="setting-label">
                    {constants.ORGANIZATION_LDAP_SETTINGS_BIND_DN}
                  </div>
                  <TextField
                    placeholder={constants.ORGANIZATION_LDAP_SETTINGS_BIND_DN}
                    required={true}
                    name="bindDn"
                    type="text"
                    value={values.bindDn}
                    disabled={true}
                  />
                </div>
                <div>
                  <div className="setting-label">
                    {constants.ORGANIZATION_LDAP_SETTINGS_BIND_CREDENTIALS}
                  </div>
                  <TextField
                    placeholder={
                      constants.ORGANIZATION_LDAP_SETTINGS_BIND_CREDENTIALS
                    }
                    required={true}
                    name="bindCredential"
                    type="text"
                    value={values.bindCredential}
                    disabled={true}
                  />
                </div>
                <div className="titlename mt-2">
                  {constants.ORGANIZATION_LDAP_USER_DN}
                </div>
                <div>
                  <TextField
                    placeholder={constants.ORGANIZATION_LDAP_USER_DN}
                    required={true}
                    name="usersDn"
                    type="text"
                    value={values.usersDn}
                    disabled={true}
                  />
                </div>
                <div className="titlename my-2">
                  {constants.ORGANIZATION_LDAP_MAP_ACCOUNT}
                </div>
                <div>
                  <div className="setting-label">
                    {constants.ORGANIZATION_LDAP_SETTINGS_FIRST_NAME}
                  </div>
                  <SelectField
                    name="firstName"
                    key="firstName"
                    options={connectionAttributes}
                    defaultOptionLabel={
                      constants.ORGANIZATION_LDAP_SETTINGS_FIRST_NAME
                    }
                    classes={'mt-1 mb-1'}
                  />
                </div>
                <div>
                  <div className="setting-label">
                    {constants.ORGANIZATION_LDAP_SETTINGS_LAST_NAME}
                  </div>
                  <SelectField
                    name="lastName"
                    key="lastName"
                    options={connectionAttributes}
                    defaultOptionLabel={
                      constants.ORGANIZATION_LDAP_SETTINGS_LAST_NAME
                    }
                    classes={'mt-1 mb-1'}
                  />
                </div>
                <div>
                  <div className="setting-label">
                    {constants.ORGANIZATION_LDAP_SETTINGS_EMAIL}
                  </div>
                  <SelectField
                    name="email"
                    key="email"
                    options={connectionAttributes}
                    defaultOptionLabel={
                      constants.ORGANIZATION_LDAP_SETTINGS_EMAIL
                    }
                    classes={'mt-1 mb-1'}
                  />
                </div>
                <div>
                  <div className="setting-label">
                    {constants.ORGANIZATION_LDAP_SETTINGS_PHONE}
                  </div>
                  <SelectField
                    name="phoneNumber"
                    key="phoneNumber"
                    options={connectionAttributes}
                    defaultOptionLabel={'Phone Number'}
                    classes={'mt-1 mb-1'}
                  />
                </div>
                <div>
                  <div className="setting-label">
                    {constants.ORGANIZATION_LDAP_SETTINGS_PP}
                  </div>
                  <SelectField
                    name="profilePicture"
                    key="profilePicture"
                    options={connectionAttributes}
                    defaultOptionLabel={'Profile Picture'}
                    classes={'mt-1 mb-1'}
                  />
                </div>
                <Col>
                  <PrimaryButton
                    className="btn btn-primary w-100 mt-3"
                    type="submit"
                    disabled={
                      !(dirty && isValid) ||
                      !values.firstName ||
                      !values.lastName ||
                      !values.email
                    }
                    loader={isSubmitting}
                    height="56px"
                    fontSize="1.125rem"
                  >
                    {constants.ORGANIZATION_LDAP_SAVE}
                  </PrimaryButton>
                </Col>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="success-container">
          <img src={warningCircle} alt="" className="m-4" />
          <PrimaryButton
            className="btn btn-primary w-100 mt-4"
            type="submit"
            height="56px"
            fontSize="1.125rem"
            loader={isLoading}
            onClick={() => {
              setIsLoading(true);
              let request = updatedSettings;
              request['ldapProviderId'] = connectionInfo.ldapProviderId;
              axios
                .put(
                  `/partner/idp/orgs/${orgId}/ldap`,
                  request,
                  Utils.requestHeader(),
                )
                .then((res) => {
                  let response = res.data;
                  if (response?.meta?.code === 200) {
                    handleClose(true);
                    setIsLoading(false);
                  } else {
                    setIsLoading(false);
                  }
                })
                .catch(function (error) {
                  setIsLoading(false);
                });
            }}
          >
            {constants.ORGANIZATION_LDAP_CONFIRM}
          </PrimaryButton>
          <PrimaryButton
            className="btn btn-outline-secondary w-100 mt-2"
            type="submit"
            height="56px"
            fontSize="1.125rem"
            backgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--brand_white')}
            borderColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40',
            )}
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            hoverColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--brand_white')}
            hoverBorderColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            onClick={() => handleClose()}
          >
            {constants.ORGANIZATION_LDAP_CANCEL}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default LdapSettings;
