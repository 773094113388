import { Outlet } from 'react-router-dom';
import ManageLicenses from './ManageLicenses';

const Licenses = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { Licenses, ManageLicenses };
