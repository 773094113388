import { useEffect, useState } from 'react';
import { constants } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import './DiscoveredDevices.scss';
import SearchableDropDown from './SearchableDropDown';

const LocationAreaSelectorModal = ({
  closeModal,
  updateDeviceLocationNArea,
  isD2CInSelectedDevices,
  listSelectedLocation,
  selectedDevicesAreas,
}) => {
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedArea, setSelectedArea] = useState();

  useEffect(() => {
    if (listSelectedLocation?.locationId) {
      setSelectedLocation(listSelectedLocation);
    }
  }, [listSelectedLocation?.locationId]);

  const onSelectLocationHandler = (locationData) => {
    if (!locationData) return;
    setSelectedLocation(locationData);
  };

  const onSelectAreaHandler = (areaData) => {
    setSelectedArea(areaData);
  };

  const onClickOkay = () => {
    updateDeviceLocationNArea(selectedLocation, selectedArea);
    closeModal();
  };

  return (
    <div className="location-area-selector-modal-wrapper">
      <div className="modal-title">
        {constants.DEVICES_MODAL_SET_LOCATION_DESC}
      </div>
      <div className="dropdown-title">
        {constants.DEVICES_LIST_LOCATION_N_AREA}
      </div>
      <div className="dropdown-container">
        <div className="dropdown-row pb-3">
          {constants.DEVICES_MODAL_LOCATION}

          <SearchableDropDown
            isLocationDropDown={true}
            isLocationDisabled={isD2CInSelectedDevices}
            isPersistLocation={true}
            onSelectLocationHandler={onSelectLocationHandler}
            preSelectedLocation={selectedLocation}
          />
        </div>
        <div className="dropdown-row pt-3 row-border">
          {constants.DEVICES_MODAL_AREA}
          <SearchableDropDown
            preSelectedLocation={selectedLocation}
            onSelectAreaHandler={onSelectAreaHandler}
            selectedDevicesAreas={selectedDevicesAreas}
          />
        </div>
      </div>
      <div className="button-container">
        <PrimaryButton
          className="device-btn-list btn-primary"
          type="button"
          width="100px"
          backgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_40')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--brand_white',
          )}
          fontSize="14px"
          height="32px"
          fontWeight="400"
          borderRadius="4px"
          onClick={() => onClickOkay()}
          disabled={!selectedLocation || !selectedArea}
        >
          {constants.DEVICES_MODAL_OK}
        </PrimaryButton>

        <PrimaryButton
          className="device-btn-list"
          type="button"
          width="100px"
          backgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--custom_color_31')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--custom_color_31')}
          borderColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--custom_color_31')}
          hoverBorderColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--custom_color_31')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_00',
          )}
          hoverColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--greyscale_00')}
          fontSize="14px"
          height="32px"
          fontWeight="400"
          borderRadius="4px"
          onClick={() => closeModal()}
        >
          {constants.DEVICES_MODAL_CANCEL}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default LocationAreaSelectorModal;
