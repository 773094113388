// All Global Constants and Enum Types should be stored here
const AppDefaults = {
  ALL_LOCATIONS_ID: 'Location0',
  ERR_CANCELED: 'ERR_CANCELED',
  CAMERA_VIDEO_TIMELINE_MAX_DURATION_IN_SECONDS: 300,
  // Min Duration: 10 seconds
  INCIDENT_EVIDENCE_CLIP_MIN_DURATION_IN_SECONDS: 10,
  // Max Duration: 240 seconds
  INCIDENT_EVIDENCE_CLIP_MAX_DURATION_IN_SECONDS: 322,
  // Default Duration: 30 seconds
  INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS: 30,
  // Width of clipper handle in pixels
  INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH: 7,
  INCIDENT_EVIDENCE_SUPPORTED_FILE_EXTENSIONS:
    '.pdf,.doc,.docx,.jpeg,.jpg,.png,.raw,.mp4,.zip',
  INCIDENT_EVIDENCE_FILE_MAX_SIZE: 10,
  INCIDENT_DATE_FORMAT: 'MMM Do YYYY',
  INCIDENT_EVIDENCE_NOTE_DATE_FORMAT: 'D MMM, YYYY HH:mm A',
  INCIDENT_FINAL_ANALYSIS_MAX_CHARACTERS: 1000,
  PROJECT_DUCLO: 'DUCLO',
  PROJECT_MEGATRON: 'MEGATRON',
  ORG_TYPE_CUSTOMER: 'CUSTOMER_ORG',
  ORG_TYPE_INSTALLER: 'INSTALLER_ORG',
  DEFAULT_TIMER_TIME: 1800000,
  FROM_TOS_PP: 3,
  FROM_TOS: 1,
  FROM_PP: 2,
  SHORTNAMEMIN: 6,
  SHORTNAMEMAX: 12,
  SCAN_NETWORK_HIDE_LOADER_TIMEOUT_IN_MILLISEC: 60000,
};

const DeviceTypeEnum = {
  NVR: 'NVR',
  IPCam: 'IPCAM',
  DMSERVER: 'DMSERVER',
  WAVE: 'WAVE',
  NWC: 'NWC',
  ONVIFCAM: 'ONVIFCAM',
};

const DeviceStatusEnum = {
  AUTHENTICATED: 'Add Device',
  ALREADY_ASSIGNED: 'Already Assigned',
  ADDED_N_CLAIMED: 'Claimed',
  ADDED_NOT_CLAIMED: 'Pending Claimed',
  DEACTIVATED: 'Reclaim Device',
  NOT_AUTHENTICATED: 'Authenticate',
  CON_OFFLINE: 'Offline',
};

const ServiceTypeEnum = {
  DM_PRO_SERVICE: 'dmpro-service',
  CLOUD_SERVICE: 'oncloud-service',
  CLOUD_STORAGE: 'cloud-storage',
  FLEXAI_SERVICE: 'flexai-service',
  SALESHUB_SERVICE: 'saleshub-service',
  SIGHT_MIND_SERVICE: 'sightmind-service',
  VMS_SERVICE: 'vms-service',
};

const LicenseStatuses = {
  ACTIVE: 'ACTIVE',
  ACTIVE_UNASSIGNED: 'ACTIVE_UNASSIGNED',
  ASSIGNED_ACTIVATION_PENDING: 'ASSIGNED_ACTIVATION_PENDING',
  EXPIRED: 'EXPIRED',
  UNASSIGNED: 'UNASSIGNED',
};

const AccountStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING_ACTIVATION: 'PENDING_ACTIVATION',
};

const DeviceConnectTypes = {
  id: 1,
  DMSERVER: {
    displayName: 'HP Bridge',
    name: 'DMSERVER',
  },
  IPCAM: {
    displayName: 'Direct to Cloud',
    name: 'IPCAM',
  },
};
const DeviceConnectionStatusTypes = {
  id: 2,
  ONLINE: {
    displayName: 'Online',
    name: 'online',
  },
  OFFLINE: {
    displayName: 'Offline',
    name: 'offline',
  },
};

const DeviceTypes = {
  id: 3,
  DEACTIVATED: {
    displayName: 'Deactivated',
    name: 'deactivated',
  },
  CLAIMING: {
    displayName: 'Claiming',
    name: 'claiming',
  },
};

const DeviceFWTypes = {
  id: 4,
  AVAILABLE: {
    displayName: 'Available',
    name: 'available',
  },
  NOTAVAILABLE: {
    displayName: 'Not available',
    name: 'notavailable',
  },
};

const DeviceAppsTypes = {
  id: 5,
  ONCLOUD: {
    displayName: 'OnCloud',
    name: 'oncloud',
  },
  SIGHTMIND: {
    displayName: 'SightMind',
    name: 'sightmind',
  },
  DMPRO: {
    displayName: 'HealthPro',
    name: 'dmpro',
  },
};

const LicenseTypes = {
  id: 6,
  PAID: {
    displayName: 'Paid',
    name: 'PAID',
  },
  TRIAL: {
    displayName: 'Trial',
    name: 'EVAL',
  },
};

const LicenseStatusType = {
  id: 7,
  AVAILABLE: {
    displayName: 'Available',
    name: 'AVAILABLE',
  },
  ACTIVE: {
    displayName: 'Active',
    name: 'ACTIVE',
  },
  EXPIRINGSOON: {
    displayName: 'Expiring soon',
    name: 'EXPIRING SOON',
  },
  EXPIRED: {
    displayName: 'Expired',
    name: 'EXPIRED',
  },
};

const DateFilterType = {
  id: 8,
  ADDEDDATE: {
    displayName: 'Added date',
    name: 'createdDate',
  },
  ACTIVATIONDATE: {
    displayName: 'Activation date',
    name: 'activeDate',
  },
  EXPIRATIONDATE: {
    displayName: 'Expiration date',
    name: 'expiryDate',
  },
};

const DiscoveredDevicesDates = {
  START: '2024-01-1',
  END: '2029-01-1',
};

export {
  AppDefaults,
  DeviceTypeEnum,
  DeviceStatusEnum,
  ServiceTypeEnum,
  LicenseStatuses,
  AccountStatuses,
  DeviceConnectTypes,
  DeviceConnectionStatusTypes,
  DeviceTypes,
  DeviceFWTypes,
  DeviceAppsTypes,
  DiscoveredDevicesDates,
  LicenseTypes,
  LicenseStatusType,
  DateFilterType,
};
