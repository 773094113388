import { useState, useEffect } from 'react';
import './SignUp.scss';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { useLocation } from 'react-router-dom';
import { Header, PrimaryButton, TextBlock } from '../../components/common';
import { constants } from '../../helpers';

function SuccessPartner() {
  const [userAgent, setUserAgent] = useState('');
  const location = useLocation();

  //=== Set token and load the partner account requests list
  useEffect(() => {
    const uagent = navigator.userAgent.toLowerCase();
    if (
      uagent.search('iphone') > -1 ||
      uagent.search('ipod') > -1 ||
      uagent.search('ipad') > -1
    ) {
      setUserAgent('ios');
    } else if (
      uagent.search('android') > -1 ||
      uagent.search('blackberry') > -1
    ) {
      setUserAgent('android');
    } else if (uagent.search('webos') > -1) {
      setUserAgent('web');
    } else {
      setUserAgent('web');
    }
  });

  return (
    <>
      <div className="marketing-pages">
        <Header isAccountScreen={true} showNavigation="false" />
        <section className="main-page customer-success-page">
          <Container>
            <Row className="justify-content-md-center">
              <Col xs lg="11">
                <Container className="main-page-content mt-5 text-center">
                  <Row>
                    <Col className="remove-padding-responsive">
                      <div className="card main-card">
                        <div className="card-body text-left">
                          <div className="text-center mb-1">
                            <TextBlock
                              fontWeight="500"
                              fontSize="1.25rem"
                              lineHeight="28px"
                            >
                              {constants.CUSTOMER_THANK_YOU_PAGE_HEADING}
                            </TextBlock>
                          </div>

                          <div className="text-center mb-3">
                            <TextBlock
                              fontWeight="700"
                              fontSize="1.5rem"
                              lineHeight="32px"
                              color="#111418"
                            >
                              {constants.CUSTOMER_THANK_YOU_PAGE_SUB_HEADING}
                            </TextBlock>
                          </div>

                          <div className="text-center mb-4">
                            <TextBlock
                              fontWeight="500"
                              fontSize="0.875rem"
                              lineHeight="20px"
                            >
                              {
                                constants.CUSTOMER_THANK_YOU_PAGE_FIELDS_INFO_HEADING
                              }
                            </TextBlock>
                          </div>

                          <Row>
                            <Col className="remove-padding-responsive">
                              <div className="card customer-success-container remove-padding-responsive">
                                <div className="card-body remove-padding-responsive text-start">
                                  <Container>
                                    <Row className="profile-heading">
                                      <Col>
                                        <TextBlock
                                          fontWeight="600"
                                          fontSize="1.25rem"
                                          lineHeight="28px"
                                          color="#000000"
                                        >
                                          {
                                            constants.CUSTOMER_THANK_YOU_PAGE_ORGANIZATION_PROFILE_HEADING
                                          }
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.account?.firstName ||
                                        location?.state?.account?.lastName
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {constants.ADMINISTRATOR_NAME_LABEL}
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {location?.state?.account?.firstName}{' '}
                                          {location?.state?.account?.lastName}
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.account?.email
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {constants.EMAIL_ADDRESS_FIELD_LABEL}
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {location?.state?.account?.email}
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.organization
                                          ?.addressLine1
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {
                                            constants.ORGANIZATION_ADDRESS_FIELD_LABEL
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {
                                            location?.state?.organization
                                              ?.addressLine1
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {
                                            location?.state?.organization
                                              ?.addressLine2
                                          }
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.organization?.orgName
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {
                                            constants.ORGANIZATION_NAME_FIELD_LABEL
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {
                                            location?.state?.organization
                                              ?.orgName
                                          }
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.organization?.shortName
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {constants.ORG_SHORT_NAME}
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {
                                            location?.state?.organization
                                              ?.shortName
                                          }
                                        </TextBlock>
                                      </Col>
                                    </Row>

                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.organization
                                          ?.phoneNumber
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {
                                            constants.ORGANIZATION_PHONE_FIELD_LABEL
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {
                                            location?.state?.organization
                                              ?.phoneNumber
                                          }
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.organization
                                          ?.orgWebsite
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {
                                            constants.ORGANIZATION_WEBSITE_NAME_FIELD_LABEL
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {
                                            location?.state?.organization
                                              ?.orgWebsite
                                          }
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.organization
                                          ?.orgLinkedInProfile
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {
                                            constants.ORGANIZATION_LINKEDIN_PROFILE_NAME_FIELD_LABEL
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {
                                            location?.state?.organization
                                              ?.orgLinkedInProfile
                                          }
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                  </Container>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-4 mb-2">
                            <Col className="remove-padding-responsive">
                              <PrimaryButton
                                className="btn btn-primary w-100"
                                onClick={() => {
                                  if (userAgent === 'web') {
                                    window.location.replace(
                                      process.env
                                        .REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
                                    );
                                  } else if (userAgent === 'android') {
                                    window.location.replace(
                                      `${process.env.REACT_APP_MOBILE_URL}login`,
                                    );
                                  } else if (userAgent === 'ios') {
                                    window.location = `${process.env.REACT_APP_MOBILE_URL}login`;
                                  } else {
                                    window.location.replace(
                                      process.env
                                        .REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
                                    );
                                  }
                                }}
                                type="submit"
                              >
                                {constants.BACK_TO_HOME_BUTTON_TITLE}
                              </PrimaryButton>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

export default SuccessPartner;
