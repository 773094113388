import { create } from 'zustand';
import { Utils, roles } from '../helpers';
import axios from 'axios';

const useCustomRoles = create((set, get) => ({
  roleList: [],
  entitlementData: [],
  usersData: [],
  selectedEntitlement: [],
  selectedUsers: [],
  selectedCustomRole: {},

  getCustomRoleList: async (route) => {
    try {
      const res = await axios.get(route, {
        ...Utils.requestHeader(),
      });

      const response = res.data;
      if (response?.meta?.code === 200) {
        const defaultRoles = [];
        const customRole = [];
        response?.data?.forEach((role) => {
          if (role.roleId !== roles.ROLE1PORTAL) {
            if (!role.customRole) {
              defaultRoles.push(role);
            } else {
              customRole.push(role);
            }
          }
        });
        customRole.sort(function (a, b) {
          if (a.roleName.toLowerCase() < b.roleName.toLowerCase()) {
            return -1;
          }
          if (a.roleName.toLowerCase() > b.roleName.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        const mergedArr = defaultRoles.concat(customRole);
        set({
          roleList: mergedArr,
        });
        return mergedArr;
      } else {
        let result;
        if (res.code) {
          result = { status: 'failure', msg: `${res.code}: ${res.message}` };
        } else if (response?.data) {
          result = { status: 'failure', msg: response?.data?.userMsg };
        } else {
          result = { status: 'failure', msg: 'Unknown internal API error' };
        }
        return result;
      }
    } catch (err) {
      console.log('error', err);
    }
  },

  setSelectedCustomRole: async (data) => {
    set({ selectedCustomRole: data });
  },
}));

export default useCustomRoles;
