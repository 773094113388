import axios from 'axios';
import { Utils } from '../../../helpers';

export const addDeviceToHubAPI = async (orgDetails, info, hubId) => {
  try {
    const response = await axios.post(
      `device/orgs/${orgDetails?.orgId}/devices/${hubId}/v2`,
      info,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data.error) {
      throw new Error('Error!');
    }
    if (data.meta.code === 200 || data.meta.code === 2303) {
      return data;
    } else {
      return data.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};
