import { IconCheckbox } from '../../assets/images';
import './Checkbox.scss';

const renderCheckbox = (checked, disabled) => {
  if (disabled) {
    return <div className="hv-checkbox-disabled" />;
  }
  if (checked) {
    return <IconCheckbox />;
  }
  return <div className="hv-checkbox-default" />;
};

const Checkbox = ({ checked, onClick, disabled }) => {
  return (
    <label className="hv-checkbox">
      <input
        type="checkbox"
        checked={checked}
        style={{ display: 'none' }}
        onChange={onClick}
        disabled={disabled}
      />
      {renderCheckbox(checked, disabled)}
    </label>
  );
};

export default Checkbox;
