import React, { useEffect, useRef, useState } from 'react';
import './ChatbotAI.scss'; // Adjust the path as needed
import { IconChatBot } from '../../assets/images';
import { HoveredIconChatBot } from '../../assets/images';

const ChatbotAI = () => {
    const chatbotRef = useRef(null); // Reference for the chatbot
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false); // State to track chatbot visibility

    useEffect(() => {
        const script = document.createElement('script');
        script.id = '__ada';
        script.src = 'https://static.ada.support/embed2.js';
        script.setAttribute('data-handle', 'hanwhatechwin');
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script); // Clean up the script on unmount
        };
    }, [isOpen]);

    const toggleChatbot = () => {
        if (window.adaEmbed) {
            window.adaEmbed.toggle()
        }
    };

    const handleIconClick = () => {
        toggleChatbot();
    };
    const handleMouseEnter = () => {
        setIsHovered(true);  
    };
    
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div ref={chatbotRef} id="ada-entry" className='icon-container'>
        <div 
            className="overlay-icon"  
            onClick={handleIconClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <img 
                src={isHovered ? HoveredIconChatBot : IconChatBot} 
                alt="Chatbot Icon" 
                className='chatbotIcon' 
            />
        </div>
    </div>
    ); 
};
 
export default ChatbotAI;