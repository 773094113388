import { useState, useEffect, useCallback } from 'react';
import { Alert, Container, Form, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Avatar, PrimaryButton } from '../../components/common';
import { TextField, ToggleInput } from '../../components/forms';
import { constants, Utils, AppDefaults } from '../../helpers';
import PageWrapper from '../PageWrapper';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import axios from 'axios';
import { HiOutlinePencil, HiUserCircle } from 'react-icons/hi2';
import './Settings.scss';
import { getPartnerOrgData } from '../../store/reducers/OrganizationsReducer';
import { useSelector } from 'react-redux';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
// Schema for yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.NAME_REQUIRED_ERROR_MESSAGE),
  lastName: Yup.string()
    .trim()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.NAME_REQUIRED_ERROR_MESSAGE),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      constants.EMAIL_VALID_ERROR_MESSAGE,
    )
    .required(constants.EMAIL_REQUIRED_ERROR_MESSAGE),
  phoneNumber: Yup.string()
    .min(0, constants.VALID_PHONE_NUMBER_ERROR_MESSAGE)
    .max(99999999999, constants.VALID_PHONE_NUMBER_ERROR_MESSAGE)
    .matches(/^\d+$/, constants.PHONE_NUMERIC_ALPHA_ERROR)
    .typeError(constants.VALID_PHONE_NUMBER_ERROR_MESSAGE),
});

function AccountSettings({ onResponse }) {
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [loctionData, setLoctionData] = useState([]);
  //=== Store get/set actions
  // const customerOrgData = useOrganizations((state) => state.customerOrgData);
  const [customerOrgData, setCustomerOrgData] = useState();
  const partnerOrgData = useSelector(getPartnerOrgData);
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );
  const getLoggedInUserAccountData = useLoggedInUserData(
    (state) => state.getLoggedInUserAccountData,
  );
  const getLoggedInUserAccountDataState = useLoggedInUserData(
    (state) => state.getLoggedInUserAccountDataState,
  );
  const setLoggedInUserData = useLoggedInUserData(
    (state) => state.setLoggedInUserData,
  );
  const loggedInUserAccountData = useLoggedInUserData(
    (state) => state.loggedInUserAccountData,
  );
  const orgDetails =
    loggedInUserData?.orgType === 'INSTALLER_ORG'
      ? partnerOrgData
      : customerOrgData?.[0];

  const [profileImgURL, setProfileImgURL] = useState(
    loggedInUserData?.image?.url,
  );
  const [sessionEnable, setSessionEnable] = useState(
    loggedInUserData?.sessionTimeout > 0,
  );
  const partnerOrgId =
    loggedInUserData?.orgType === 'INSTALLER_ORG'
      ? partnerOrgData?.orgId
      : customerOrgData?.[0]?.orgId;

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustomerOrgData(orgs);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  const fetchLoogedInUserData = async () => {
    try {
      let fetchResult = await getLoggedInUserAccountData(
        `partner/orgs/${partnerOrgId}/accounts/${loggedInUserData?.accountId}`,
      );
      if (fetchResult?.status === 'success') {
        const data = getLoggedInUserAccountDataState();
        const loggedData = loggedInUserData;
        const updatedLoggedInData = {
          ...data,
          orgType: loggedData?.orgType,
          orgId: loggedData?.orgId,
          role: loggedData?.role,
        };
        setLoggedInUserData(updatedLoggedInData);
      }
    } catch (error) {
      setUserMsg(error);
    }
  };

  const fetchLocations = async () => {
    if (loggedInUserData?.orgType === AppDefaults?.ORG_TYPE_CUSTOMER) {
      const resp = await axios.get(
        `partner/orgs/${loggedInUserData?.orgId}/accounts/${loggedInUserData?.accountId}/locationAreas`,
        Utils.requestHeader(),
      );
      const response = resp?.data;
      if (parseInt(response?.meta?.code) === 200) {
        setLoctionData(response?.data);
      }
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    if (partnerOrgId && loggedInUserData) {
      fetchLoogedInUserData();
    }
  }, [
    partnerOrgId,
    JSON.stringify(getLoggedInUserAccountDataState),
    profileImgURL,
  ]);

  const handleAccountProfilePictureChange = async (event) => {
    const input = event.target;
    const selectedFile = input.files[0];
    input.value = '';
    const allowedExtensions = ['jpg', 'jpeg', 'png'];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        const fileStr = await Utils.encodeImageFileToBase64String(selectedFile);

        if (fileStr && partnerOrgId && loggedInUserData?.accountId) {
          axios
            .put(
              `partner/orgs/${partnerOrgId}/accounts/${loggedInUserAccountData?.accountId}/image`,
              {
                image: fileStr.split(',')[1],
              },
              Utils.requestHeader(),
            )
            .then((res) => {
              let response = res.data;
              if (response?.meta?.code === 200) {
                setProfileImgURL(fileStr);
                setUserMsg(
                  constants.ACCOUNT_SETTINGS_PROFILE_IMAGE_SUCCESS_TEXT,
                );
                setVariant('success');
              } else {
                setUserMsg(response?.meta?.userMsg);
                setVariant('danger');
              }
            })
            .catch(function (error) {
              setUserMsg(error.message);
              setVariant('danger');
            });
        }
      } else {
        setUserMsg(constants.ACCOUNT_SETTINGS_PROFILE_IMAGE_ERROR_TEXT);
        setVariant('danger');
      }
    }
  };

  return (
    <div className="App account-settings">
      <PageWrapper className="mb-5 ps-0 mw-100">
        <div className="list-block">
          <Container className="h-100 mw-100 ps-0">
            <Row className="g-4">
              <Col md={6}>
                <Alert
                  variant={variant}
                  show={!!userMsg}
                  onClose={() => setUserMsg('')}
                  dismissible
                >
                  {userMsg}
                </Alert>
                <Formik
                  initialValues={{
                    accountId: loggedInUserAccountData?.accountId,
                    accountStatus: loggedInUserAccountData?.accountStatus,
                    image: profileImgURL,
                    firstName: loggedInUserAccountData?.firstName,
                    lastName: loggedInUserAccountData?.lastName,
                    phoneNumber: loggedInUserAccountData?.phoneNumber,
                    email: loggedInUserAccountData?.email,
                    role: loggedInUserAccountData?.role,
                    sessionEnable: loggedInUserData?.sessionTimeout !== -1,
                  }}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    // Simulate submitting to database
                    if (partnerOrgId && loggedInUserAccountData?.accountId) {
                      if (
                        loggedInUserData?.orgType ===
                        AppDefaults?.ORG_TYPE_CUSTOMER
                      ) {
                        values.locations = loctionData;
                      }
                      if (sessionEnable) {
                        values.sessionTimeout =
                          loggedInUserData?.sessionTimeout > 0
                            ? loggedInUserData?.sessionTimeout
                            : 30;
                      } else {
                        values.sessionTimeout = -1;
                      }
                      axios
                        .put(
                          `partner/orgs/${partnerOrgId}/accounts/${loggedInUserAccountData?.accountId}`,
                          values,
                          Utils.requestHeader(),
                        )
                        .then((res) => {
                          let response = res.data;
                          if (response?.meta?.code === 200) {
                            setSubmitting(false);
                            fetchLoogedInUserData();
                            setVariant('success');
                            onResponse(
                              constants.ACCOUNT_SETTINGS_PROFILE_SETTINGS_SUCCESS_TEXT,
                              'success',
                            );
                          } else {
                            setVariant('danger');
                            setUserMsg(response?.meta?.userMsg);
                            setSubmitting(false);
                          }
                        })
                        .catch(function (error) {
                          setVariant('danger');
                          setUserMsg(error.message);
                          setSubmitting(false);
                        });
                    } else setSubmitting(false);
                  }}
                >
                  {({
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    dirty,
                    setFieldValue,
                    values,
                  }) => (
                    <>
                      <div className=" d-flex account-custom-class align-items-center gap-4">
                        <label className="col-sm-4 form-label col-form-label ">
                          {constants.PROFILE_IMAGE}
                        </label>
                        <div className="text-start mb-3 col-sm-8 account-profile-icon">
                          {profileImgURL ? (
                            <Avatar
                              valueType="icon"
                              value={profileImgURL}
                              size="extraLarge"
                              avatarStyle="roundedCircle"
                            />
                          ) : (
                            <HiUserCircle size={88} />
                          )}
                          <div
                            className={`edit-pencil-icon ${orgDetails?.ldapEnabled ? 'disabled-icon' : ''}`}
                          >
                            <label htmlFor="inputGroupFile">
                              <HiOutlinePencil />
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              className="form-control"
                              id="inputGroupFile"
                              onChange={(event) =>
                                handleAccountProfilePictureChange(event)
                              }
                              disabled={orgDetails?.ldapEnabled}
                            />
                          </div>
                        </div>
                      </div>
                      <Form className="modal-form" onSubmit={handleSubmit}>
                        <TextField
                          removebottommargin="true"
                          removetopmargin="true"
                          label="First name*"
                          placeholder={constants.FIRST_NAME_FIELD}
                          name="firstName"
                          type="text"
                          disabled={orgDetails?.ldapEnabled}
                          className="account-custom-class"
                          inputClassName="custom-input-class"
                          isColSm={true}
                        />
                        <TextField
                          removebottommargin="true"
                          removetopmargin="true"
                          label="Last name*"
                          placeholder={constants.LAST_NAME_FIELD}
                          name="lastName"
                          type="text"
                          disabled={orgDetails?.ldapEnabled}
                          className="account-custom-class"
                          inputClassName="custom-input-class"
                          isColSm={true}
                        />
                        <TextField
                          removebottommargin="true"
                          removetopmargin="true"
                          label="Phone number"
                          placeholder={constants.PHONE_NUMBER_FIELD}
                          name="phoneNumber"
                          maxLength={11}
                          type="text"
                          onChange={(e) => {
                            setFieldValue('phoneNumber', e.target.value);
                          }}
                          disabled={orgDetails?.ldapEnabled}
                          className="account-custom-class"
                          inputClassName="custom-input-class"
                          isColSm={true}
                        />
                        <TextField
                          removebottommargin="true"
                          removetopmargin="true"
                          label="E-mail address*"
                          placeholder={constants.EMAIL_ADDRESS_FIELD}
                          name="email"
                          type="text"
                          disabled={true}
                          className="account-custom-class"
                          inputClassName="custom-input-class"
                          isColSm={true}
                        />
                        <div className="row account-custom-class">
                          <label className="col-sm-4 form-label col-form-label">
                            {constants.SESSION_OUT_TITLE}
                          </label>
                          <div className="col-sm-8 text-start d-flex">
                            <span class="session-title">
                              {loggedInUserData?.sessionTimeout > 0
                                ? constants.SESSION_OUT_MESSAGE.replace(
                                    '30',
                                    loggedInUserData?.sessionTimeout,
                                  )
                                : constants.SESSION_OUT_MESSAGE}
                            </span>
                            <ToggleInput
                              label={''}
                              customClass="toggle-field-one"
                              name="sessionEnable"
                              changeHandler={(e) => {
                                setSessionEnable(!sessionEnable);
                                setFieldValue('sessionEnable', !sessionEnable);
                              }}
                              value={values?.sessionEnable}
                              isAddEdit={true}
                            />
                          </div>
                        </div>

                        <Form.Group as={Row} className="mt-4">
                          <Col className="text-start profile-btn">
                            <PrimaryButton
                              className="btn btn-primary"
                              type="submit"
                              disabled={!(dirty && isValid)}
                              loader={isSubmitting}
                              height="32px"
                              fontSize="0.875rem"
                              width="100px"
                            >
                              {constants.ACCOUNT_SETTINGS_PROFILE_BUTTON_LABEL}
                            </PrimaryButton>
                          </Col>
                        </Form.Group>
                      </Form>
                    </>
                  )}
                </Formik>
              </Col>
              <Col md={6}></Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </div>
  );
}

export default AccountSettings;
