import { useEffect, useState } from 'react';
import { constants } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import { Radio, RadioGroup } from '@mui/material';
import './DiscoveredDevices.scss';
import SearchableDropDown from './SearchableDropDown';
import { getAreasByLocationId } from './DevicesAPI';
import './DiscoveredDevicesRegister.scss';

const DiscoveredDevicesRegister = ({
  closeModal,
  onRegisterDevices,
  isD2CInSelectedDevices,
  isToggleClaim,
  isAddAllSelected,
  isD2CInDevices,
}) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedArea, setSelectedArea] = useState();
  const [reclaimOption, setReclaimOption] = useState(
    constants.DISCOVERED_DEVICES_RECLAIM,
  );

  useEffect(() => {
    if (
      (!isD2CInSelectedDevices &&
        !isAddAllSelected &&
        selectedLocation?.locationId) ||
      (isAddAllSelected && !isD2CInDevices && selectedLocation?.locationId)
    ) {
      setSelectedArea(null);
      fetchAreasbyLocationId();
    }
  }, [selectedLocation]);

  const fetchAreasbyLocationId = async () => {
    if (selectedLocation && selectedLocation.locationId) {
      const locationData = { locationId: selectedLocation.locationId };
      const areaAPIResponse = await getAreasByLocationId(
        selectedLocation.orgId,
        locationData,
      );
      if (areaAPIResponse?.length > 0) {
        const defaultArea = areaAPIResponse?.find((x) => x.isDefault);
        setSelectedArea(defaultArea);
      }
    }
  };

  const onSelectLocationHandler = (locationData) => {
    if (!locationData) return;
    setSelectedLocation(locationData);
  };

  const onSelectAreaHandler = (areaData) => {
    setSelectedArea(areaData);
  };

  const onClickOkay = () => {
    onRegisterDevices(selectedLocation, selectedArea, reclaimOption);
    closeModal();
  };

  return (
    <div className="location-area-selector-modal-wrapper">
      <div className="modal-title">
        {isToggleClaim ? (
          <>{constants.DEVICES_MODAL_SET_LOCATION_DESC_CLAIM}</>
        ) : (
          <>{constants.DEVICES_MODAL_SET_LOCATION_DESC_RECLAIM}</>
        )}
      </div>
      {!isToggleClaim ? (
        <>
          <div className="d-flex mt-4">
            <RadioGroup
              aria-labelledby="base-details-radio-buttons"
              name="controlled-radio-buttons-group"
            >
              <Radio
                sx={{
                  color: getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--greyscale_80'),
                }}
                checked={reclaimOption === constants.DISCOVERED_DEVICES_RECLAIM}
                onChange={(e) => {
                  setReclaimOption(constants.DISCOVERED_DEVICES_RECLAIM);
                }}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
              />
            </RadioGroup>

            <div className="mx-2">
              <div className="device-setup-modal-subTitle">
                {constants.DISCOVERED_DEVICES_RECLAIM_OPT1_TITLE}
              </div>
              <div className="discription mt-1">
                {constants.DISCOVERED_DEVICES_RECLAIM_OPT1_INFO}
              </div>
            </div>
          </div>
          <div className="d-flex mt-4 mb-2">
            <RadioGroup
              aria-labelledby="base-details-radio-buttons"
              name="controlled-radio-buttons-group"
            >
              <Radio
                sx={{
                  color: getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--greyscale_80'),
                }}
                checked={reclaimOption === constants.DISCOVERED_DEVICES_ADD}
                onChange={(e) => {
                  setReclaimOption(constants.DISCOVERED_DEVICES_ADD);
                }}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
              />
            </RadioGroup>
            <div className="mx-2">
              <div className="device-setup-modal-subTitle">
                {constants.DISCOVERED_DEVICES_RECLAIM_OPT2_TITLE}
              </div>
              <div className="discription mt-1">
                {constants.DISCOVERED_DEVICES_RECLAIM_OPT2_INFO}
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className="dropdown-title">
        {(!isD2CInSelectedDevices && !isAddAllSelected) ||
        (isAddAllSelected && !isD2CInDevices) ? (
          <>{constants.DEVICES_LIST_LOCATION}</>
        ) : (
          <>{constants.DEVICES_LIST_LOCATION_N_AREA}</>
        )}
      </div>
      <div className="dropdown-container">
        <div
          className={`dropdown-row ${isD2CInSelectedDevices || (isAddAllSelected && isD2CInDevices) ? 'pb-3' : ''}`}
        >
          {constants.DEVICES_MODAL_LOCATION}
          <SearchableDropDown
            isLocationDropDown={true}
            onSelectLocationHandler={onSelectLocationHandler}
          />
        </div>
        {isD2CInSelectedDevices || (isAddAllSelected && isD2CInDevices) ? (
          <div className="dropdown-row pt-3 row-border">
            {constants.DEVICES_MODAL_AREA}
            <SearchableDropDown
              preSelectedLocation={selectedLocation}
              onSelectAreaHandler={onSelectAreaHandler}
            />
          </div>
        ) : null}
      </div>
      <div className="button-container">
        <PrimaryButton
          className="device-btn-list btn-primary"
          type="button"
          width="100px"
          backgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_40')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--brand_white',
          )}
          fontSize="14px"
          height="32px"
          fontWeight="400"
          borderRadius="4px"
          onClick={() => onClickOkay()}
          disabled={!selectedLocation || !selectedArea}
        >
          {constants.DEVICES_MODAL_OK}
        </PrimaryButton>

        <PrimaryButton
          className="device-btn-list"
          type="button"
          width="100px"
          backgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--custom_color_31')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--custom_color_31')}
          borderColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--custom_color_31')}
          hoverBorderColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--custom_color_31')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_00',
          )}
          hoverColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--greyscale_00')}
          fontSize="14px"
          height="32px"
          fontWeight="400"
          borderRadius="4px"
          onClick={() => closeModal()}
        >
          {constants.DEVICES_MODAL_CANCEL}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default DiscoveredDevicesRegister;
