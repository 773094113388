import * as React from 'react';
import { constants, AccountStatuses } from '../../helpers/';

interface NonInstallerUser {
  accountId: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  activeDate: number;
  disableDate: number;
  accountStatus: string;
  createdDate: number;
  updatedDate: number;
  accountLanguage: string;
  phoneNumber: string;
  role: string;
  isLocked: boolean;
  canShareAndDownload: boolean;
  sessionTimeout: number;
}

const UsersSection = ({
  goToUser,
  nonInstallerUsers,
  accountId,
}: {
  goToUser: () => void;
  nonInstallerUsers: NonInstallerUser[];
  accountId: string;
}) => {
  const activeUsers = nonInstallerUsers?.filter(
    (user) =>
      user?.accountStatus === AccountStatuses.ACTIVE &&
      user?.isLocked !== true &&
      user?.accountId !== accountId,
  );
  const inactiveUsers = nonInstallerUsers?.filter(
    (user) =>
      user?.accountStatus === AccountStatuses.INACTIVE &&
      user?.accountId !== accountId,
  );

  const invitedUsers = nonInstallerUsers?.filter(
    (user) =>
      user?.accountStatus === AccountStatuses.PENDING_ACTIVATION &&
      user?.accountId !== accountId,
  );

  const lockedUsers = nonInstallerUsers?.filter(
    (user) =>
      user?.accountStatus === AccountStatuses.ACTIVE &&
      user?.isLocked === true &&
      user?.accountId !== accountId,
  );

  return (
    <div className="accounts-details">
      <div className="header-layout">
        <div className="header-label">
          {constants.TOP_HEADER_USERS_NAV_TITLE}
        </div>
        <div className="manage-label" onClick={() => goToUser()}>
          {constants.SIDEBAR_USERS_SUBMENU_MANAGE_LABEL}
        </div>
      </div>
      <div className="account-status-layout">
        <div className="block-one-status">
          <div className="label-name">{activeUsers?.length}</div>
          <div className="label-value">{constants.ORG_LABELS_ACTIVATED}</div>
        </div>
        <div className="block-one-status">
          <div className="label-name">{inactiveUsers?.length}</div>
          <div className="label-value">{constants.ORG_LABELS_INACTIVE}</div>
        </div>
      </div>
      <div className="account-status-layout">
        <div className="block-one-status">
          <div className="label-name highlight-bg"> {invitedUsers?.length}</div>
          <div className="label-value highlight-sec">
            {constants.ORG_LABELS_INVITED}
          </div>
        </div>
        <div className="block-one-status">
          <div className="label-name highlight-bg">{lockedUsers?.length}</div>
          <div className="label-value highlight-sec">
            {constants.ORG_LABLES_LOCKED}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersSection;
