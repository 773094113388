import { PrimaryButton, SiteModal } from '../../../../../components/common';
import { constants } from '../../../../../helpers';

const AddErrorModal = ({ onHideModal, errorMessage }) => {
  return (
    <SiteModal
      modalTitle={constants.DEVICES_MODAL_Add}
      showModal={true}
      hideModal={() => {
        onHideModal();
      }}
      classes="gateway-operations-popup"
      key="authenticate-channel-modal"
      modalFooter={
        <>
          <PrimaryButton
            className={`btn-primary`}
            type="submit"
            width="100px"
            backgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_white',
            )}
            fontSize="0.875rem"
            height="32px"
            fontWeight="400"
            borderRadius="4px"
            onClick={() => onHideModal()}
          >
            {constants.SCAN_NETWORK_OK_BTN_LABEL}
          </PrimaryButton>
        </>
      }
    >
      <div className="sub-title mb-4">{errorMessage}</div>
    </SiteModal>
  );
};

export default AddErrorModal;
