import constants from './en';

const roles = {
  ROLE0PORTAL: 'role0#portal',
  ROLE1PORTAL: 'role1#portal',
  ROLE2PORTAL: 'role2#portal',
  ROLE3PORTAL: 'role3#portal',
  ROLE4PORTAL: 'role4#portal',
  ROLE5PORTAL: 'role5#portal',
  ROLE6PORTAL: 'role6#portal',
  ROLE7PORTAL: 'role7#portal'
}

export const rolesNames = {
  'role7#portal': constants.SUPER_ADMIN,
  'role6#portal': constants.SUPER_PARTNER_ADMIN,
  'role5#portal': constants.PARTNER_USER,
  'role4#portal': constants.PARTNER_ADMIN,
  'role3#portal': constants.USER,
  'role2#portal' : constants.ADMIN,
  'role1#portal': constants.TECHNICIAN,
}

export default roles;
