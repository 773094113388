import { useState } from 'react';
import { Alert, Container, Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { PrimaryButton } from '../../components/common';
import { TextField } from '../../components/forms';
import { Utils, constants } from '../../helpers';
import axios from 'axios';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FaRegCircleCheck } from 'react-icons/fa6';
import './Settings.scss';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required(constants.CURRENT_PASSWORD_REQUIRED),
  newPassword: Yup.string()
    .required(constants.NEW_PASSWORD_REQUIRED)
    .min(8, constants.PASSWORD_MUST_EIGHT_CHAR)
    .notOneOf(
      [Yup.ref('currentPassword')],
      constants.PASSWORD_SHOULD_NOT_BE_SAME,
    )
    .matches(/[A-Z]/, constants.MUST_ONE_UPPER_LETTER)
    .matches(/[a-z]/, constants.MUST_ONE_LOWER_LETTER)
    .matches(/[0-9]/, constants.MUST_ONE_NUMBER)
    .matches(/[!@#$%^&*]/, constants.MUST_ONE_SPECIAL_CHAR),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], constants.PASSWORD_MUST_MATCH)
    .required(constants.CONFIRM_PASSWORD_REQUIRED),
});

function UpdatePassword({ onResponse }) {
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [passwordShown, setPasswordShown] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  /**
   *
   * SHOW PASSWORD AND CONFIRM PASSWORD METHOD HANDLING
   */
  const setPasswordShowHide = (field) => {
    setPasswordShown((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  /**
   *
   * CHANGE PASSWORD API INEGRATION
   */
  const callChangePasswordAPI = async (values, { resetForm }) => {
    const reqBody = {
      pwd: values.newPassword,
      confirmPwd: values.confirmPassword,
      currentPwd: values.currentPassword,
    };

    try {
      const res = await axios.post(`/user/pwd`, reqBody, Utils.requestHeader());

      const response = res.data;
      if (parseInt(response?.meta?.code) === 200) {
        onResponse(
          constants.ACCOUNT_SETTINGS_PASSWORD_SETTINGS_SUCCESS_TEXT,
          'success',
        );
        setVariant('success');
        setSubmitting(false);
        resetForm();
      } else {
        setUserMsg(response?.meta?.userMsg);
        setVariant('danger');
        setSubmitting(false);
      }
    } catch (error) {
      setUserMsg('Error updating password. Please try again.');
      setVariant('danger');
      setSubmitting(false);
    }
  };

  /**
   *
   * CHECK VALIDATION METHOD FOR UI
   */
  const renderPasswordRow = (condition, text) => (
    <div className="row-text-left d-flex">
      <FaRegCircleCheck
        style={{
          color: condition ? 'var(--success_48)' : 'var(--greyscale_80)',
        }}
      />
      <span className="password-hints left-to-icon">{text}</span>
    </div>
  );

  return (
    <div className="App update-password">
      <div className="list-block">
        <Container className="h-100 mw-100">
          <Alert
            variant={variant}
            show={!!userMsg}
            onClose={() => setUserMsg('')}
            dismissible
          >
            {userMsg}
          </Alert>
          <div className="g-4 w-100">
            <Formik
              initialValues={{
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
              }}
              validationSchema={validationSchema}
              initialErrors={{
                currentPassword: 'Current password is required',
                newPassword: 'New password is required',
                confirmPassword: 'Confirm password is required',
              }}
              initialTouched={{
                currentPassword: false,
                newPassword: false,
                confirmPassword: false,
              }}
              initialStatus={{
                isValid: false,
              }}
              onSubmit={(values, { resetForm }) => {
                setSubmitting(true);
                callChangePasswordAPI(values, { resetForm });
              }}
            >
              {({ values, isValid, handleSubmit }) => (
                <Form
                  className="modal-form d-flex flex-row"
                  onSubmit={handleSubmit}
                >
                  <Col md={6}>
                    <TextField
                      afterinputicon={
                        <div
                          onClick={() => setPasswordShowHide('currentPassword')}
                        >
                          {!passwordShown.currentPassword ? (
                            <AiOutlineEyeInvisible />
                          ) : (
                            <AiOutlineEye />
                          )}
                        </div>
                      }
                      label="Current password*"
                      placeholder={constants.CURRENT_PASSWORD}
                      name="currentPassword"
                      type={passwordShown.currentPassword ? 'text' : 'password'}
                      className="account-custom-class"
                      inputClassName="custom-input-class"
                      isColSm={true}
                      hideWarningCircle={true}
                    />
                    <div className="pb-3 row d-flex justify-content-end">
                      <div className="col-sm-8">
                        <Row>
                          <Col className="row-text-left row-title">
                            {constants.PASSWORD_REQUIREMENTS}
                          </Col>
                        </Row>
                        <Row className="m-t-8">
                          <Col>
                            {renderPasswordRow(
                              values.newPassword.length >= 8,
                              constants.AT_LEAST_8_CHAR,
                            )}
                          </Col>
                        </Row>

                        <Row className="m-t-8">
                          <Col>
                            {renderPasswordRow(
                              /[A-Z]/.test(values.newPassword),
                              constants.ONE_UPPER_CASE_LETTER,
                            )}
                          </Col>
                        </Row>

                        <Row className="m-t-8">
                          <Col>
                            {renderPasswordRow(
                              /[a-z]/.test(values.newPassword),
                              constants.ONE_LOWER_CASE_LETTER,
                            )}
                          </Col>
                        </Row>

                        <Row className="m-t-8">
                          <Col>
                            {renderPasswordRow(
                              /[0-9]/.test(values.newPassword),
                              constants.AT_LEAST_ONE_NUMBER,
                            )}
                          </Col>
                        </Row>

                        <Row className="m-t-8">
                          <Col>
                            {renderPasswordRow(
                              /[!@#$%^&*]/.test(values.newPassword),
                              constants.ONE_SPECIAL_CHAR,
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <TextField
                        afterinputicon={
                          <div
                            onClick={() => setPasswordShowHide('newPassword')}
                          >
                            {!passwordShown.newPassword ? (
                              <AiOutlineEyeInvisible />
                            ) : (
                              <AiOutlineEye />
                            )}
                          </div>
                        }
                        label="New password*"
                        placeholder={constants.NEW_PASSWORD_FIELD}
                        name="newPassword"
                        type={passwordShown.newPassword ? 'text' : 'password'}
                        className="account-custom-class"
                        inputClassName="custom-input-class"
                        isColSm={true}
                        hideWarningCircle={true}
                      />
                    </div>

                    <div>
                      <TextField
                        afterinputicon={
                          <div
                            onClick={() =>
                              setPasswordShowHide('confirmPassword')
                            }
                          >
                            {!passwordShown.confirmPassword ? (
                              <AiOutlineEyeInvisible />
                            ) : (
                              <AiOutlineEye />
                            )}
                          </div>
                        }
                        label="Confirm password*"
                        placeholder={constants.CONFIRM_PASSWORD_FIELD}
                        name="confirmPassword"
                        type={
                          passwordShown.confirmPassword ? 'text' : 'password'
                        }
                        className="account-custom-class"
                        inputClassName="custom-input-class"
                        isColSm={true}
                        hideWarningCircle={true}
                      />
                    </div>
                    <Form.Group as={Row} className="mt-4">
                      <Col className="text-start profile-btn">
                        <PrimaryButton
                          className="btn btn-primary"
                          type="submit"
                          loader={isSubmitting}
                          height="32px"
                          fontSize="0.875rem"
                          width="100px"
                          disabled={!isValid}
                        >
                          {constants.ACCOUNT_SETTINGS_PROFILE_BUTTON_LABEL}
                        </PrimaryButton>
                      </Col>
                    </Form.Group>
                  </Col>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default UpdatePassword;
