import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi';
import { constants } from '../../../helpers';
import { SiteSpinner } from '../../../components/common';
import './DiscoveredDevices.scss';
import {
  getAreasByLocationIdWithPagination,
  getLocationsByPagination,
} from './DevicesAPI';
import InfiniteScroll from 'react-infinite-scroller';
import { IoIosSearch } from 'react-icons/io';
import { IoCloseCircle } from 'react-icons/io5';
import { PiWarningCircleLight } from 'react-icons/pi';
import highlightWords from 'highlight-words';
import {
  setDevicesSelectedLocation,
  getDevicesSelectedLocation,
} from '../../../store/reducers/AccountReducer';

const SearchableDropDown = ({
  isFromDeviceList,
  isLocationDropDown,
  isLocationDisabled,
  isPersistLocation,
  setLocationDetails,
  onSelectLocationHandler,
  onSelectAreaHandler,
  setPreSelectedLocation,
  preSelectedLocation,
  selectedDevicesAreas,
}) => {
  const defaultLocationPageDetails = {
    page: 0,
    size: 10,
    orderBy: 'locationName',
    isAsc: true,
  };
  const defaultAreaPageDetails = {
    page: 0,
    size: 10,
    orderBy: 'areaName',
    isAsc: true,
  };
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const locationId = searchParams.get('locationId');
  const [isMoreDataRequested, setIsMoreDataRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageDetails, setPageDetails] = useState(
    isLocationDropDown ? defaultLocationPageDetails : defaultAreaPageDetails,
  );
  const [searchText, setSearchText] = useState('');
  const [searchedText, setSearchedText] = useState('');
  const [selectedLocation, setSelectedLocation] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [onLoading, setOnLoading] = useState(false);
  const devicesSelectedLocation = useSelector(getDevicesSelectedLocation);
  const dispatch = useDispatch();
  const [locationAreas, setLocationAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState();

  useEffect(() => {
    if (isLocationDropDown) {
      getLocationsWithPagination(searchText);
    } else if (selectedLocation) {
      fetchAreasbyLocationIdWithPagination(selectedLocation, searchText);
    }
  }, [JSON.stringify(pageDetails)]);

  useEffect(() => {
    if (isMoreDataRequested && !isLoading) {
      const rawPageDetails = structuredClone(pageDetails);
      rawPageDetails.page = pageDetails.page + 1;
      setPageDetails(rawPageDetails);
    }
  }, [isMoreDataRequested]);

  useEffect(() => {
    if (
      preSelectedLocation &&
      preSelectedLocation?.locationId !== selectedLocation?.locationId
    ) {
      setSelectedLocation(preSelectedLocation);
      if (!isLocationDropDown) {
        setLocationAreas([]);
        setSelectedArea(null);
        onSelectAreaHandler(null);
        if (
          JSON.stringify(pageDetails) === JSON.stringify(defaultAreaPageDetails)
        ) {
          fetchAreasbyLocationIdWithPagination(preSelectedLocation, searchText);
        } else {
          setPageDetails(defaultAreaPageDetails);
        }
      }
    }
  }, [JSON.stringify(preSelectedLocation)]);

  const updateSelectedLocation = (locationDetails) => {
    if (!locationDetails) {
      return;
    }

    let finalLocation = null;
    let defaultLocation = locationDetails.filter(
      (location) => location.isDefault === 'true',
    )?.[0];

    if (!defaultLocation) {
      defaultLocation = locationDetails[0];
    }
    if (defaultLocation) {
      defaultLocation.orgId = orgId;
    }
    if (locationId) {
      const locationById = locationDetails.filter(
        (location) => location.locationId === locationId,
      )?.[0];

      if (locationById) {
        locationById.orgId = orgId;
        finalLocation = locationById;
      } else {
        finalLocation = defaultLocation;
      }
    } else if (
      isPersistLocation &&
      devicesSelectedLocation &&
      devicesSelectedLocation.orgId === orgId
    ) {
      finalLocation = devicesSelectedLocation;
    } else if (defaultLocation) {
      finalLocation = defaultLocation;
    }

    setSelectedLocation(finalLocation);

    if (isFromDeviceList) {
      dispatch(setDevicesSelectedLocation(finalLocation));
      if (setPreSelectedLocation) {
        setPreSelectedLocation(finalLocation);
      }
    }

    onSelectLocationHandler(finalLocation);
  };

  const getLocationsWithPagination = async (search) => {
    if (!isLoading) {
      setIsLoading(true);
    } else {
      return;
    }
    setSearchText(search?.trim());

    let locationsAPIData = await getLocationsByPagination(
      orgId,
      pageDetails,
      search?.trim(),
    );

    if (locationsAPIData && !locationsAPIData.userMsg) {
      if (!onLoading) {
        setOnLoading(true);
        updateSelectedLocation(locationsAPIData.locations);
      }
      if (locationsAPIData.locations) {
        locationsAPIData.locations = locationsAPIData.pageNumber
          ? [...locations, ...locationsAPIData.locations]
          : locationsAPIData.locations;
        setLocations(locationsAPIData.locations);
      }
      if (isFromDeviceList) {
        setLocationDetails(locationsAPIData.locations);
      }

      setTotalPages(locationsAPIData.totalPages);
      setIsMoreDataRequested(false);
      setIsLoading(false);
      setSearchedText(search?.trim());
    } else {
      setIsMoreDataRequested(false);
      setIsLoading(false);
    }
  };

  const fetchAreasbyLocationIdWithPagination = async (locationData, search) => {
    if (!isLoading) {
      setIsLoading(true);
    } else {
      return;
    }
    setSearchText(search?.trim());

    let AreasAPIData = await getAreasByLocationIdWithPagination(
      orgId,
      locationData,
      pageDetails,
      search?.trim(),
    );

    if (AreasAPIData && !AreasAPIData?.userMsg) {
      if (AreasAPIData.area) {
        AreasAPIData.area = AreasAPIData.pageNumber
          ? [...locationAreas, ...AreasAPIData.area]
          : AreasAPIData.area;
        setLocationAreas(AreasAPIData.area);
      }

      const sArea = getDefaultSelectedAreaFromList(AreasAPIData.area);

      if (sArea) {
        setSelectedArea(sArea);
        onSelectAreaHandler(sArea);
      }

      setTotalPages(AreasAPIData.totalPages);
      setIsMoreDataRequested(false);
      setIsLoading(false);
      setSearchedText(search?.trim());
    } else {
      setIsMoreDataRequested(false);
      setIsLoading(false);
    }
  };

  const getLocationData = (locationId) => {
    if (!locationId) return;

    return locations.find((location) => location.locationId === locationId);
  };

  const getDefaultSelectedAreaFromList = (areas) => {
    let finalArea = null;

    let defaultArea = areas?.filter((area) => area.isDefault)?.[0];
    if (!defaultArea) {
      defaultArea = areas?.[0];
    }
    if (selectedDevicesAreas?.length) {
      const allEqual = selectedDevicesAreas.every(
        (area) => area === selectedDevicesAreas?.[0],
      );
      if (allEqual) {
        const areaById = areas?.filter(
          (area) => area.areaId === selectedDevicesAreas?.[0],
        )?.[0];
        finalArea = areaById ? areaById : defaultArea;
      } else {
        finalArea = defaultArea;
      }
    } else {
      finalArea = defaultArea;
    }
    return finalArea;
  };

  const onSelectLocationHandle = (evtKey) => {
    if (!evtKey) return;

    const locationData = getLocationData(evtKey);
    setSelectedLocation(locationData);
    setLocationAreas([]);
    setSelectedArea(null);
    onSelectLocationHandler(locationData);
  };

  const onSelectAreaHandle = (evtKey) => {
    if (!evtKey) return;

    let selectedArea = locationAreas.find((area) => area.areaId === evtKey);
    setSelectedArea(selectedArea);
    onSelectAreaHandler(selectedArea);
  };

  const loadMore = () => {
    if (!isMoreDataRequested && !isLoading) {
      setIsMoreDataRequested(true);
    }
  };

  const locationDropdownContent =
    locations?.length > 0 ? (
      locations?.map((location) => {
        let chunks = [];
        chunks = highlightWords({
          text: location.locationName,
          query: searchedText,
          matchExactly: true,
        });

        return (
          <Dropdown.Item
            key={`location-${location.locationId}`}
            className={`searchable-selector-menu-item ${
              selectedLocation?.locationId === location.locationId
                ? 'selected'
                : ''
            }`}
            eventKey={location.locationId}
          >
            <span className={'searchStyles'}>
              {searchedText
                ? chunks.map(({ text, match, key }) =>
                    match ? (
                      <span className="searched-text" key={key}>
                        {text}
                      </span>
                    ) : (
                      <span key={key}>{text}</span>
                    ),
                  )
                : location.locationName}
            </span>
          </Dropdown.Item>
        );
      })
    ) : !locations?.length && isLoading ? (
      <div className="default-container">
        <SiteSpinner width="32px" height="32px" />
      </div>
    ) : (
      <div className="default-container">
        <div className="no-locations-container">
          <div>
            <PiWarningCircleLight size={32} color="#000000C4" />
          </div>
          <div>{constants.LOCATION_DROPDOWN_NO_LOCATION_DATA_TEXT}</div>
        </div>
      </div>
    );

  const areaDropdownContent =
    locationAreas?.length > 0 ? (
      locationAreas?.map((area) => {
        let chunks = [];
        chunks = highlightWords({
          text: area.areaName,
          query: searchedText,
          matchExactly: true,
        });

        return (
          <Dropdown.Item
            key={`area-${area.areaId}`}
            className={
              `searchable-selector-menu-item` +
              (selectedArea?.areaId === area.areaId ? ' selected' : '')
            }
            eventKey={area.areaId}
          >
            <span className={'searchStyles'}>
              {searchedText
                ? chunks.map(({ text, match, key }) =>
                    match ? (
                      <span className="searched-text" key={key}>
                        {text}
                      </span>
                    ) : (
                      <span key={key}>{text}</span>
                    ),
                  )
                : area.areaName}
            </span>
          </Dropdown.Item>
        );
      })
    ) : !locationAreas?.length && isLoading ? (
      <div className="default-container">
        <SiteSpinner width="32px" height="32px" />
      </div>
    ) : (
      <div className="default-container">
        <div className="no-locations-container">
          <div>
            <PiWarningCircleLight size={32} color="#000000C4" />
          </div>
          <div>{constants.LOCATION_DROPDOWN_NO_AREA_DATA_TEXT}</div>
        </div>
      </div>
    );

  return (
    <>
      {isLocationDropDown ? (
        <Dropdown
          className="searchable-selector mr-3"
          onSelect={(e) => {
            setIsMenuOpen(!isMenuOpen);
            onSelectLocationHandle(e);
          }}
          show={isMenuOpen}
          autoClose={'outside'}
          onToggle={(e) => setIsMenuOpen(e)}
        >
          <Dropdown.Toggle
            variant="outline-secondary"
            id="dropdown-location"
            className="searchable-selector-toggle"
            disabled={isLocationDisabled}
            onClick={(e) => setIsMenuOpen(!isMenuOpen)}
          >
            <div className="area-selected-wrapper">
              <span className="area-selected-name">
                {selectedLocation?.locationName}
              </span>
            </div>
            {isMenuOpen ? (
              <HiOutlineChevronUp size={20} />
            ) : (
              <HiOutlineChevronDown size={20} />
            )}
          </Dropdown.Toggle>
          <div className="drop-down-container">
            {isMenuOpen ? (
              <div
                className="search-container"
                onClick={(e) => e.stopPropagation()}
              >
                <IoIosSearch
                  className="search-icon"
                  size={20}
                  role="button"
                  onClick={() => {
                    setLocations([]);
                    if (pageDetails?.page) {
                      setPageDetails(defaultLocationPageDetails);
                    } else {
                      getLocationsWithPagination(searchText.trim());
                    }
                  }}
                />
                <input
                  type={'text'}
                  placeholder={'Search'}
                  className="search-input"
                  value={searchText}
                  onChange={(e) => setSearchText(e?.target?.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setLocations([]);
                      if (pageDetails?.page) {
                        setPageDetails(defaultLocationPageDetails);
                      } else {
                        getLocationsWithPagination(e.target?.value);
                      }
                    }
                  }}
                />
                {searchText ? (
                  <IoCloseCircle
                    className="search-close"
                    size={16}
                    onClick={() => {
                      setSearchText('');
                      setSearchedText('');
                      setLocations([]);
                      if (
                        JSON.stringify(pageDetails) ===
                        JSON.stringify(defaultLocationPageDetails)
                      ) {
                        getLocationsWithPagination('');
                      } else {
                        setPageDetails(defaultLocationPageDetails);
                      }
                    }}
                    color="#747E87"
                    role="button"
                  />
                ) : null}
              </div>
            ) : null}
            <Dropdown.Menu className="searchable-selector-menu" flip={false}>
              <InfiniteScroll
                loadMore={() => loadMore()}
                hasMore={
                  totalPages > pageDetails?.page + 1 && !isMoreDataRequested
                }
                pageStart={0}
                initialLoad={false}
                useWindow={false}
                threshold={1}
              >
                {locationDropdownContent}
              </InfiniteScroll>
              {isMoreDataRequested && locations?.length ? (
                <Dropdown.Item className="loader-container">
                  <SiteSpinner width="20px" height="20px" />
                </Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          </div>
        </Dropdown>
      ) : (
        <Dropdown
          className="searchable-selector"
          onSelect={(e) => {
            setIsMenuOpen(!isMenuOpen);
            onSelectAreaHandle(e);
          }}
          show={isMenuOpen}
          autoClose={'outside'}
          onToggle={(e) => setIsMenuOpen(e)}
        >
          <Dropdown.Toggle
            variant="outline-secondary"
            id="dropdown-area"
            className="searchable-selector-toggle"
            disabled={isLoading && !pageDetails?.page}
            onClick={(e) => setIsMenuOpen(!isMenuOpen)}
          >
            <div className="area-selected-wrapper">
              <span className="area-selected-name">
                {selectedArea?.areaName}
              </span>
            </div>
            {isMenuOpen ? (
              <HiOutlineChevronUp size={20} />
            ) : (
              <HiOutlineChevronDown size={20} />
            )}
          </Dropdown.Toggle>
          <div className="drop-down-container">
            {isMenuOpen ? (
              <div
                className="search-container"
                onClick={(e) => e.stopPropagation()}
              >
                <IoIosSearch
                  className="search-icon"
                  size={20}
                  role="button"
                  onClick={() => {
                    setLocationAreas([]);
                    if (pageDetails?.page) {
                      setPageDetails(defaultAreaPageDetails);
                    } else {
                      fetchAreasbyLocationIdWithPagination(
                        selectedLocation,
                        searchText.trim(),
                      );
                    }
                  }}
                />
                <input
                  type={'text'}
                  placeholder={'Search'}
                  className="search-input"
                  value={searchText}
                  onChange={(e) => setSearchText(e?.target?.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setLocationAreas([]);
                      if (pageDetails?.page) {
                        setPageDetails(defaultAreaPageDetails);
                      } else {
                        fetchAreasbyLocationIdWithPagination(
                          selectedLocation,
                          e.target?.value,
                        );
                      }
                    }
                  }}
                />
                {searchText ? (
                  <IoCloseCircle
                    className="search-close"
                    size={16}
                    onClick={() => {
                      setSearchText('');
                      setSearchedText('');
                      setLocationAreas([]);
                      if (
                        JSON.stringify(pageDetails) ===
                        JSON.stringify(defaultAreaPageDetails)
                      ) {
                        fetchAreasbyLocationIdWithPagination(
                          selectedLocation,
                          '',
                        );
                      } else {
                        setPageDetails(defaultAreaPageDetails);
                      }
                    }}
                    color="#747E87"
                    role="button"
                  />
                ) : null}
              </div>
            ) : null}
            <Dropdown.Menu className="searchable-selector-menu" flip={false}>
              <InfiniteScroll
                loadMore={() => loadMore()}
                hasMore={
                  totalPages > pageDetails?.page + 1 && !isMoreDataRequested
                }
                pageStart={0}
                initialLoad={false}
                useWindow={false}
                threshold={1}
              >
                {areaDropdownContent}
              </InfiniteScroll>
              {isMoreDataRequested && locationAreas?.length ? (
                <Dropdown.Item className="loader-container">
                  <SiteSpinner width="20px" height="20px" />
                </Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          </div>
        </Dropdown>
      )}
    </>
  );
};

export default SearchableDropDown;
