import { useEffect } from 'react';
import { PrimaryButton, SiteModal } from '../../../../../components/common';
import { constants, DeviceTypeEnum } from '../../../../../helpers';

const MoveModal = ({
  channelsToBeMoved,
  gatewayDeviceData,
  onHideModal,
  onOkBtnClick,
  scannedDevicesTree,
  defaultGatewayArea,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleMoveDevice();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const getChannelPayload = (device) => {
    return {
      channel: device?.channel,
      locationId: gatewayDeviceData?.locationId,
      deviceType: device?.deviceType,
      deviceId: device?.deviceId,
    };
  };

  const handleMoveDevice = () => {
    let payload = [];
    if (gatewayDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR) {
      const parentDevicesList = [];
      channelsToBeMoved.forEach((selectedChannel) => {
        const selectedMac = selectedChannel.macAddress;
        scannedDevicesTree.forEach((item) => {
          const parentMac = item.macAddress;
          if (selectedMac === parentMac) {
            const parentIndex = parentDevicesList.findIndex(
              (x) => x.macAddress === parentMac,
            );
            if (parentIndex === -1) {
              parentDevicesList.push(item);
              // parentDevicesList.push({ ...item, channels: [selectedChannel] });
              // } else {
              //   const parent = parentDevicesList[parentIndex];
              //   parent.channels.push(selectedChannel);
            }
          }
        });
      });
      payload = parentDevicesList.map((parentDevice) => {
        return {
          ...getChannelPayload(parentDevice),
          deviceName: parentDevice.deviceName || parentDevice.serialNumber,
          areaId: parentDevice.areaId || defaultGatewayArea.areaId,
          channels:
            parentDevice.channels?.map((channel) => {
              return {
                ...getChannelPayload(channel),
                deviceName: channel.deviceName || channel.serialNumber,
                areaId: channel.areaId || defaultGatewayArea.areaId,
              };
            }) || [],
        };
      });
    } else {
      payload = channelsToBeMoved.map((item) => {
        return {
          ...getChannelPayload(item),
          deviceName: item.deviceName || item.serialNumber,
          areaId: item.areaId || defaultGatewayArea.areaId,
          channels:
            item.channels?.map((channel) => {
              return {
                ...getChannelPayload(channel),
                deviceName: channel.deviceName || channel.serialNumber,
                areaId: channel.areaId || defaultGatewayArea.areaId,
              };
            }) || [],
        };
      });
    }
    onOkBtnClick(payload);
  };

  return (
    <SiteModal
      modalTitle={constants.DEVICES_MODAL_MOVE_POPUP_TITLE}
      showModal={true}
      hideModal={() => {
        onHideModal();
      }}
      classes="gateway-operations-popup"
      key="authenticate-channel-modal"
      modalFooter={
        <>
          <PrimaryButton
            className={`btn-primary`}
            type="submit"
            width="100px"
            backgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_white',
            )}
            fontSize="14px"
            height="32px"
            fontWeight="400"
            borderRadius="4px"
            onClick={() => handleMoveDevice()}
          >
            {constants.SCAN_NETWORK_OK_BTN_LABEL}
          </PrimaryButton>
          <div
            className="primary-btn cancel-btn"
            role="button"
            onClick={() => onHideModal()}
          >
            {constants.SCAN_NETWORK_CANCEL_BTN_LABEL}
          </div>
        </>
      }
    >
      <div className="sub-title mb-4">
        {constants.SCAN_NETWORK_MOVE_SUB_TITLE.replace(
          '{gatewayDeviceName}',
          gatewayDeviceData?.deviceName,
        )}
      </div>
    </SiteModal>
  );
};

export default MoveModal;
