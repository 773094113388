import React, { useState, useRef, useEffect } from "react";
import "./CustomAreaDropdown.scss";
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";

const CustomAreaDropdown = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isPositionCalculated, setIsPositionCalculated] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setIsOpen((prev) => !prev);
    setIsPositionCalculated(false); // Reset position calculation on toggle
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  // Hide dropdown on scroll within the modal body
  useEffect(() => {
    const container = document.querySelector(".scan-network-modal");
    const modalBody = container
      ? container.querySelector(".modal-body-responsive")
      : null;

    const handleScroll = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (modalBody) {
      modalBody.addEventListener("scroll", handleScroll, true);
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      if (modalBody) {
        modalBody.removeEventListener("scroll", handleScroll, true);
      }
    };
  }, []);

  // Recalculate position only once when dropdown is opened
  useEffect(() => {
    if (isOpen && buttonRef.current && !isPositionCalculated) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setPosition({
        top: buttonRect.bottom + window.scrollY,
        left: buttonRect.left + window.scrollX,
      });
      setIsPositionCalculated(true); // Position calculated
    }
  }, [isOpen, isPositionCalculated]);

  // Render the dropdown outside the normal flow (appended to body)
  useEffect(() => {
    if (isOpen && isPositionCalculated) {
      const dropdownMenu = document.createElement("div");
      dropdownMenu.classList.add("custom-dropdown-menu");
      dropdownMenu.style.position = "absolute";
      dropdownMenu.style.display = "block";
      dropdownMenu.style.top = `${position.top}px`;
      dropdownMenu.style.left = `${position.left}px`;
      dropdownMenu.style.zIndex = 9999;
      dropdownMenu.style.width = "180px";

      // Add dropdown options to the dynamically created div
      options.forEach((option) => {
        const optionElement = document.createElement("div");
        optionElement.classList.add("custom-dropdown-item");
        if (option.areaId === value) {
          optionElement.classList.add("selected");
        }
        optionElement.innerText = option.areaName;
        optionElement.addEventListener("click", () => {
          onChange(option);
          setIsOpen(false); // Close dropdown after selection
        });
        dropdownMenu.appendChild(optionElement);
      });

      // Append the dropdown to the body
      document.body.appendChild(dropdownMenu);

      // Cleanup when the dropdown is closed
      return () => {
        document.body.removeChild(dropdownMenu);
      };
    }
  }, [isOpen, position, options, onChange, isPositionCalculated]);

  return (
    <div
      className="dropdown-container relative inline-block w-64"
      ref={dropdownRef}
    >
      <div
        ref={buttonRef}
        className="dropdown-field w-full border rounded-md bg-white"
        onClick={handleDropdownToggle}
      >
        {options.find((option) => option.areaId === value)?.areaName ||
          "Select area"}
        {isOpen ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
      </div>
    </div>
  );
};

export default CustomAreaDropdown;
