import { Outlet } from 'react-router-dom';
import CustomerLandingPage from './CustomerLandingPage';
import EditOrganization from './EditOrganization';
import ManageOrganizations from './ManageOrganizations';
import NewOrganization from './NewOrganization';
import OrgListTable from './OrgListTable';
import PartnerLandingPage from './PartnerLandingPage';
import LdapAuthentication from './LdapAuthentication';
import LdapSettings from './LdapSettings';
const Organizations = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export {
  CustomerLandingPage,
  EditOrganization,
  ManageOrganizations,
  NewOrganization,
  Organizations,
  OrgListTable,
  PartnerLandingPage,
  LdapAuthentication,
  LdapSettings,
};
