import axios from 'axios';
import { Utils } from '../../../helpers';
import {
  DeviceAppsTypes,
  DeviceConnectionStatusTypes,
  DeviceFWTypes,
  DeviceTypes,
  DeviceConnectTypes,
} from '../../../helpers/enums';

export const gatewaysAPI = async (
  orgId,
  locationId,
  pageDetails,
  searchText,
  filtersData,
  deviceTypes,
) => {
  const connectType =
    filtersData?.connectType?.length ===
    Object.keys(DeviceConnectTypes).length - 1
      ? ['all']
      : filtersData?.connectType;

  const statusList =
    [...filtersData?.connectionStatus, ...filtersData?.deviceStatus]?.length ===
    Object.keys(DeviceConnectionStatusTypes).length +
      Object.keys(DeviceTypes).length -
      2
      ? ['all']
      : [...filtersData?.connectionStatus, ...filtersData?.deviceStatus];

  const applicationsLinked =
    filtersData?.applicationsLinked?.length ===
    Object.keys(DeviceAppsTypes).length - 1
      ? ['all']
      : filtersData?.applicationsLinked;

  const firmwareUpdate =
    filtersData?.firmwareUpdate?.length ===
    Object.keys(DeviceFWTypes).length - 1
      ? ['all']
      : filtersData?.firmwareUpdate;

  try {
    const response = await axios.get(
      `device/orgs/${orgId}/gateways/v2?locationId=${locationId}&page=${
        pageDetails?.page
      }
      &size=${pageDetails?.size}&orderBy=${pageDetails?.orderBy}&isAsc=${
        pageDetails?.isAsc
      }
      &search=${searchText ? encodeURIComponent(searchText) : ''}&deviceTypes=${
        filtersData?.connectType?.length ? connectType.join('%2C') : ''
      }&statusList=${
        filtersData?.connectionStatus?.length ||
        filtersData?.deviceStatus?.length
          ? statusList.join('%2C')
          : ''
      }&apps=${
        filtersData?.applicationsLinked?.length
          ? applicationsLinked.join('%2C')
          : ''
      }&firmwareUpdates=${
        filtersData?.firmwareUpdate?.length ? firmwareUpdate.join('%2C') : ''
      }`,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const channelsAPI = async (
  orgId,
  device,
  locationId,
  pageDetails,
  searchText,
  filtersData,
  deviceTypes,
) => {
  const connectType =
    filtersData?.connectType?.length ===
    Object.keys(DeviceConnectTypes).length - 1
      ? ['all']
      : filtersData?.connectType;

  const statusList =
    [...filtersData?.connectionStatus, ...filtersData?.deviceStatus]?.length ===
    Object.keys(DeviceConnectionStatusTypes).length +
      Object.keys(DeviceTypes).length -
      2
      ? ['all']
      : [...filtersData?.connectionStatus, ...filtersData?.deviceStatus];

  const applicationsLinked =
    filtersData?.applicationsLinked?.length ===
    Object.keys(DeviceAppsTypes).length - 1
      ? ['all']
      : filtersData?.applicationsLinked;

  const firmwareUpdate =
    filtersData?.firmwareUpdate?.length ===
    Object.keys(DeviceFWTypes).length - 1
      ? ['all']
      : filtersData?.firmwareUpdate;

  try {
    const response = await axios.get(
      `device/orgs/${orgId}/gateways/${
        device?.deviceId
      }/devices/v2?locationId=${locationId}&page=${
        device?.childPageDetails ? device?.childPageDetails?.page + 1 : 0
      }
      &orderBy=${pageDetails?.orderBy}&isAsc=${pageDetails?.isAsc}&search=${
        searchText ? encodeURIComponent(searchText) : ''
      }&deviceTypes=${
        filtersData?.connectType?.length ? connectType.join('%2C') : ''
      }&statusList=${
        filtersData?.connectionStatus?.length ||
        filtersData?.deviceStatus?.length
          ? statusList.join('%2C')
          : ''
      }&apps=${
        filtersData?.applicationsLinked?.length
          ? applicationsLinked.join('%2C')
          : ''
      }&firmwareUpdates=${
        filtersData?.firmwareUpdate?.length ? firmwareUpdate.join('%2C') : ''
      }`,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const renameChannelAPI = async (orgId, device, newName) => {
  const reqBody = {
    newAreaId: device?.areaId,
    deviceName: newName,
  };

  try {
    const response = await axios.put(
      `/device/orgs/${orgId}/locations/${device?.locationId}/areas/${device?.areaId}/devices/${device?.deviceId}`,
      reqBody,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const removeChannelAPI = async (orgId, device) => {
  try {
    const response = await axios.delete(
      `/device/orgs/${orgId}/devices/${device?.deviceId}/v2`,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    return data?.meta;
  } catch (err) {
    throw new Error(err);
  }
};

export const getDeviceTypesAPI = async (orgId) => {
  try {
    const response = await axios.get(
      `/device/orgs/${orgId}/deviceTypes`,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const updateDeviceLoctaionAreaAPI = async (
  orgId,
  location,
  area,
  devices,
) => {
  const reqBody = {
    newAreaId: area?.areaId,
    deviceIds: devices,
  };

  try {
    const response = await axios.put(
      `/device/orgs/${orgId}/locations/${location?.locationId}/devices`,
      reqBody,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const updateDeviceAreaAPI = async (orgId, location, area, devices) => {
  const reqBody = {
    deviceIds: devices,
  };

  try {
    const response = await axios.put(
      `/device/orgs/${orgId}/locations/${location?.locationId}/areas/${area?.areaId}/devices`,
      reqBody,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const announcedDevicesAPI = async (
  orgId,
  pageDetails,
  searchText,
  filtersData,
  filterDate,
  deviceTypes,
) => {
  const connectType =
    filtersData?.connectType?.length ===
    Object.keys(DeviceConnectTypes).length - 1
      ? ['all']
      : filtersData?.connectType;

  const connectionStatus =
    filtersData?.connectionStatus?.length ===
    Object.keys(DeviceConnectionStatusTypes).length - 1
      ? ['all']
      : filtersData?.connectionStatus;

  const end = new Date(filterDate?.endDate);
  end.setHours(23, 59, 59, 0);

  try {
    const response = await axios.get(
      `device/orgs/${orgId}/devices/discover/v3?page=${pageDetails?.page}
      &size=${pageDetails?.size}&orderBy=${pageDetails?.orderBy}&isAsc=${
        pageDetails?.isAsc
      }
      &search=${searchText ? searchText : ''}&deviceTypes=${
        filtersData?.connectType?.length ? connectType.join('%2C') : ''
      }&connectionStatus=${
        filtersData?.connectionStatus?.length
          ? connectionStatus.join('%2C')
          : ''
      }&startDate=${new Date(
        filterDate?.startDate,
      ).getTime()}&endDate=${end.getTime()}`,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchDevicesBysNOMacAPI = async (orgId, list) => {
  try {
    const response = await axios.post(
      `/device/orgs/${orgId}/devices/search`,
      { devices: list },
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const updateAnnouncedDevicesAPI = async (orgId, requestObj) => {
  try {
    const response = await axios.put(
      `device/orgs/${orgId}/devices/discover`,
      requestObj,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const removeAnnouncedDevicesAPI = async (orgId, requestObj) => {
  try {
    const response = await axios.delete(
      `device/orgs/${orgId}/devices/discover`,
      {
        headers: {
          'X-Correlation-ID': Utils.generateUUID(),
        },
        data: { deviceIds: requestObj },
      },
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    return data?.meta;
  } catch (err) {
    throw new Error(err);
  }
};

export const addAnnouncedDevicesAPI = async (orgId, addRequestedDevices) => {
  try {
    const response = await axios.post(
      `device/orgs/${orgId}/devices/v3`,
      { devices: addRequestedDevices },
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const reclaimAnnouncedDevicesAPI = async (
  orgId,
  reclaimRequestedDevices,
) => {
  try {
    const response = await axios.put(
      `device/orgs/${orgId}/devices`,
      { devices: reclaimRequestedDevices },
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const addAllAnnouncedDevicesAPI = async (
  orgId,
  isToggleClaim,
  attachAsNew,
  location,
  area,
) => {
  const param = {
    reclaimDevice: !isToggleClaim,
    locationId: location?.locationId,
    areaId: area?.areaId,
  };
  if (!isToggleClaim) {
    param.reattach = attachAsNew;
  }
  try {
    const response = await axios.post(
      `device/orgs/${orgId}/gateways/claim`,
      param,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getAreasByLocationId = async (orgId, location) => {
  try {
    const response = await axios.get(
      `/partner/orgs/${orgId}/locations/${location?.locationId}/area`,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getLocationsByPagination = async (
  orgId,
  pageDetails,
  searchText,
) => {
  try {
    const response = await axios.get(
      `/partner/orgs/${orgId}/locations/v2?page=${pageDetails?.page}
      &size=${pageDetails?.size}
      &searchKeys=${pageDetails?.orderBy}
      &isAsc=${pageDetails?.isAsc}
      &search=${searchText ? encodeURIComponent(searchText) : ''}`,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getAreasByLocationIdWithPagination = async (
  orgId,
  location,
  pageDetails,
  searchText,
) => {
  try {
    const response = await axios.get(
      `/partner/orgs/${orgId}/locations/${location?.locationId}/area/v2?page=${pageDetails?.page}
      &size=${pageDetails?.size}
      &searchKeys=${pageDetails?.orderBy}
      &isAsc=${pageDetails?.isAsc}
      &search=${searchText ? encodeURIComponent(searchText) : ''}`,
      Utils.requestHeader(),
    );
    const data = await response.data;
    if (data?.error) {
      throw new Error('Error!');
    }
    if (data?.meta?.code === 200 || data?.meta?.code === 2303) {
      return data?.data;
    } else {
      return data?.meta;
    }
  } catch (err) {
    throw new Error(err);
  }
};
