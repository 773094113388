import { useState, useEffect } from "react";

const AvatarUtility = (userData) => {
    const [isImageValid, setIsImageValid] = useState(true);
  
    const firstName = userData?.firstName?.charAt(0)?.toUpperCase() || '';
    const lastName = userData?.lastName?.charAt(0)?.toUpperCase() || '';
    const initials = firstName + lastName;
    const imageUrl = userData?.image?.url;
  
    useEffect(() => {
      if (imageUrl) {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => setIsImageValid(true);
        img.onerror = () => setIsImageValid(false);
      } else {
        setIsImageValid(false);
      }
    }, [imageUrl]);
  
    return {
      valueType: isImageValid ? "icon" : "text",
      value: isImageValid ? imageUrl : initials,
    };
  };

  export default AvatarUtility;