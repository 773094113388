import { constants } from '../../../helpers';
import ResizableTable from './ResizableTable';

const ScanNetworkModalWithArea = ({
  displayHeaderDetails,
  displayGatewayDetails,
}) => {
  const paginatedHeaderList = [
    {
      displayName: '',
      id: 'checkbox',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_NAME,
      id: 'deviceName',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_MODEL,
      id: 'modelNumber',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_SN,
      id: 'serialNumber',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_IP,
      id: 'ipAddress',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_PORT,
      id: 'port',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_AREA,
      id: 'areaName',
    },
  ];

  return (
    <>
      <ResizableTable
        widths={[45, 190, 140, 160, 120, 120, 200]}
        minWidths={[45, 190, 140, 160, 120, 120, 200]}
        minWidth={45}
        disabledColumns={[0, 5]}
        displayHeaderDetails={() => displayHeaderDetails(paginatedHeaderList)}
        displayGatewayDetails={() => displayGatewayDetails()}
      />
    </>
  );
};

export default ScanNetworkModalWithArea;
