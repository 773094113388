/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  PrimaryButton,
  SiteModal,
  SiteToast,
  TextBlock,
} from '../../components/common';
import { TextField } from '../../components/forms';
import { constants, Utils, roles, AppDefaults } from '../../helpers';
import ManageAreas from './ManageAreas';
import { useSearchParams } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';
import { usePoliciesStore } from '../../store/policiesStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import timezones from '../../data/support/timezone.json';
import { PiWarningCircleBold } from 'react-icons/pi';
import { useOrganizations } from '../../store/OrganizationsStore';
import {
  findTimeZoneFromSelectedValue,
  moveArrayPosition,
  compareAreaNames,
} from '../../helpers/commonUtils';
import { IoClose } from 'react-icons/io5';
import { TbInfoCircle } from 'react-icons/tb';
import LocationSelectField from '../../components/forms/LocationSelectField';
import { getAllDevicesData } from '../../store/AccountStoreIDB';
import CustomSelect from '../../components/common/CustomSelect';

// Schema for yup
const validationSchema = Yup.object().shape({
  locationName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .required(constants.NAME_REQUIRED_ERROR_MESSAGE),
  addressLine1: Yup.string().required(constants.ADDRESS_REQUIRED_ERROR_MESSAGE),
  timezone: Yup.string().required(constants.TIMEZONE_REQUIRED_ERROR_MESSAGE),
});

function EditLocations(props) {
  const childFunc = React.useRef(null);
  const locationsData = props?.locationsData;
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [isDuplicateLocation, setIsDuplicateLocation] = useState(false);
  const [isShowDuplicateError, setIsShowDuplicateError] = useState(false);
  const [visibleLocHelperText, setVisibleLocHelperText] = useState(false);
  const [locHelperText, setLocHelperText] = useState('');
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [btnStatus, setBtnStatus] = useState(true);
  const [newLocaAreas, setNewLocaAreas] = useState(
    locationsData?.areas?.length > 0 ? locationsData?.areas : [],
  );
  const [showRemoveLocationModal, setShowRemoveLocationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [deviceExists, setDeviceExists] = useState([]);
  //=== Store get/set actions
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );
  const zoneTimeList = timezones?.data;
  const [currentUserTimezone, setCurrentUserTimezone] = useState(
    findTimeZoneFromSelectedValue(locationsData?.timezone),
  );
  const [showRemoveAreaModal, setShowRemoveAreaModal] = useState(false);
  const [removeAreaName, setRemoveAreaName] = useState(null);
  const [removeAreaIndex, setRemoveAreaIndex] = useState(null);
  const [editLocAreaList, setEditLocAreaList] = useState([]);
  const [isAreaUpdated, setIsAreaUpdated] = useState(false);

  const [orgContact, setOrgContact] = useState([]);
  const getOrganizationContact = useOrganizations(
    (state) => state.getOrganizationContact,
  );
  const getCustomerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocationsData,
  );

  const getCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocations,
  );

  const tenantID =
    !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
      ? 'dc'
      : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
        ? 'hva'
        : 'dc';
  const appID =
    !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
      ? 'vms'
      : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
        ? 'portal'
        : 'vms';

  const disableInput = (event, values) => {
    if (values.addressLine1) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      const filteredDeviceList = devices?.filter(
        (device) => device.locationId === locationsData?.locationId,
      );
      setDeviceExists(filteredDeviceList);
    };

    fetchDevices();
  }, []);

  useEffect(() => {
    if (orgId) {
      // get data for organization contact dropdown
      const roleParam = [roles.ROLE2PORTAL, roles.ROLE7PORTAL];
      const roleParamEncoded = roleParam
        .map((role) => encodeURIComponent(role))
        .join(',');
      getOrganizationContact(
        `/partner/orgs/${orgId}/accounts/v2?roleIds=${roleParamEncoded}`,
      )
        .then((res) => {
          if (res?.status === 'failure') {
            setUserMsg(res?.msg);
          } else {
            let updatedContacts = res ?? [];
            if (locationsData?.contactId !== '') {
              const selectedObjIndex = res?.findIndex(
                (data) => data?.value === locationsData?.contactId,
              );
              updatedContacts = moveArrayPosition(res, selectedObjIndex, 0);
            }
            setOrgContact(updatedContacts);
          }
        })
        .catch((err) => {
          setUserMsg(err?.msg);
        });
    }
  }, [orgId]);

  //=== On click remove this account button remove user and update user data
  const handleRemoveLocationClick = () => {
    setShowLoader(true);
    setUserMsg('');
    axios
      .delete(
        `partner/orgs/${orgId}/locations/${locationsData?.locationId}`,
        Utils.requestHeader(),
      )
      .then((res) => {
        let response = res.data;
        if (response?.meta?.code === 200) {
          props.reloadData();
          props.hideModal();
          setShowRemoveLocationModal(false);
        } else {
          setVariant('error');
          setUserMsg(response?.meta?.userMsg);
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        setVariant('error');
        setUserMsg(error?.message);
        setShowLoader(false);
      });
  };

  const changeTimezoneValue = (event, setFieldValue) => {
    let selectedVal = event?.target?.value;
    const getLocationSelected = findTimeZoneFromSelectedValue(selectedVal);
    setCurrentUserTimezone(getLocationSelected);
    setFieldValue('timezone', getLocationSelected?.location);
    setVisibleLocHelperText(false);
  };
  const checkAddedAreas = () => {
    if (newLocaAreas.some((area) => area.isDefault && !area.areaName)) {
      return true;
    }
    const nameEmpty = newLocaAreas.filter((area) => !area.areaName);
    if (nameEmpty.length === newLocaAreas.length) {
      return true;
    }
    return false;
  };

  const handleRemoveAreaBtnClick = () => {
    if (removeAreaIndex !== null && removeAreaName !== null) {
      let newAreaData = newLocaAreas?.filter(
        (area, index) =>
          area.areaName !== removeAreaName && index !== removeAreaIndex,
      );
      setNewLocaAreas(newAreaData);
    }
    setShowRemoveAreaModal(false);
  };

  useEffect(() => {
    if (editLocAreaList[0]?.areas?.length && newLocaAreas?.length) {
      setIsAreaUpdated(
        compareAreaNames(newLocaAreas, editLocAreaList[0]?.areas),
      );
    }
  }, [newLocaAreas]);

  useEffect(() => {
    getLocations();
  }, []);

  const getLocations = async () => {
    await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
    const myLocation = getCustomerOrgLocationsData();
    setEditLocAreaList(
      myLocation?.filter((item) => item?.locationId === props?.locationId),
    );
  };

  const checkDuplicateLocationName = (values) => {
    const duplicateLocations = props.orgLocationsData.filter(
      (location) =>
        location?.locationName?.trim().toLowerCase() ===
          values?.locationName?.trim().toLowerCase() &&
        location?.locationId !== values?.locationId,
    );
    setIsShowDuplicateError(true);
    setIsDuplicateLocation(duplicateLocations?.length);
  };

  const fetchTimezone = async (latitude, longitude, setFieldValue) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}user/maps/timezone?latitude=${latitude}&longitude=${longitude}&timestamp=${Math.floor(new Date().getTime() / 1000)}`;

      const response = await axios.get(url, {
        headers: {
          'X-tenant-id': tenantID,
          'X-app-Id': appID,
        },
      });
      const json = response?.data?.data;

      if (json?.status === 'OK') {
        const data = findTimeZoneFromSelectedValue(json?.timeZoneId);
        setCurrentUserTimezone(data);
        setFieldValue('timezone', data?.location || '');
        setLocHelperText(
          data
            ? constants.LOCATION_TIMEZONE_ORGANIZATION_MESSAGE
            : constants.AUTO_TIMEZONE_FAIL_MESSAGE,
        );
      } else {
        setLocHelperText(constants.AUTO_TIMEZONE_FAIL_MESSAGE);
      }
    } catch (error) {
      setLocHelperText(constants.AUTO_TIMEZONE_FAIL_MESSAGE);
    } finally {
      setVisibleLocHelperText(true);
    }
  };

  return (
    <section className="modal-main-content">
      <div
        className={`parent-edit-modal ${
          showRemoveLocationModal || showRemoveAreaModal ? 'd-none' : ''
        }`}
      >
        <Formik
          initialValues={{
            locationId: locationsData?.locationId,
            locationName: locationsData?.locationName,
            displayDetails: `${locationsData?.addressLine1}${locationsData?.city}, ${locationsData?.state}, ${locationsData?.country}, ${locationsData?.zipcode}`,
            addressLine1: locationsData?.addressLine1,
            addressLine2: locationsData?.addressLine2,
            city: locationsData?.city,
            state: locationsData?.state,
            country: locationsData?.country,
            zipcode: locationsData?.zipcode,
            lat: locationsData?.lat,
            lng: locationsData?.lng,
            contactId: locationsData?.contactId ?? '',
            isDefault: locationsData?.isDefault,
            areas: locationsData?.areas?.length > 0 ? locationsData?.areas : [],
            timezone: currentUserTimezone?.location,
            // bandwidth: locationsData?.locationContact ?? ''
          }}
          // Hooks up our validationSchema to Formik
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            setUserMsg('');
            if (orgId !== null && orgId !== undefined) {
              // Simulate submitting to database, shows us values submitted, resets form
              values.areas = newLocaAreas !== undefined ? newLocaAreas : [];
              axios
                .put(
                  `partner/orgs/${orgId}/locationAreas`,
                  values,
                  Utils.requestHeader(),
                )
                .then((res) => {
                  let response = res.data;
                  if (response?.meta?.code === 200) {
                    setSubmitting(false);
                    props.reloadData();
                    props.hideModal();
                  } else {
                    setVariant('error');
                    setUserMsg(response?.meta?.userMsg);
                    setSubmitting(false);
                  }
                })
                .catch(function (error) {
                  setVariant('error');
                  setUserMsg(error?.message);
                });
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            dirty,
            isValid,
          }) => (
            <>
              <Form className="form" onSubmit={handleSubmit}>
                {isDuplicateLocation && isShowDuplicateError ? (
                  <div className="duplication-location-container">
                    <div className="left-container">
                      <TbInfoCircle color="#641616" size={20} />
                      <div>
                        {constants.DUPLICATE_LOCATION_NAME_ERROR_MESSAGE}
                      </div>
                    </div>
                    <IoClose
                      color="#641616"
                      size={20}
                      role="button"
                      onClick={() => setIsShowDuplicateError(false)}
                    />
                  </div>
                ) : null}
                <TextField
                  required={true}
                  removebottommargin="true"
                  removetopmargin="true"
                  label=""
                  placeholder={constants.LOCATION_NAME_FIELD}
                  name="locationName"
                  type="text"
                  isDuplicateLocation={isDuplicateLocation}
                  onKeyUp={() => checkDuplicateLocationName(values)}
                />
                <div className="row">
                  <div className="col position-relative mt-2 address-with-reset">
                    <div>
                      <Autocomplete
                        defaultValue={values && values.addressLine1}
                        name="displayDetails"
                        placeholder=""
                        apiKey={process.env.REACT_APP_GOOGLE_PLACE_URL}
                        onChange={(evt) => {
                          setFieldValue('displayDetails', evt?.target?.value);
                        }}
                        onBlur={handleBlur}
                        onKeyDown={(e) => disableInput(e, values)}
                        id="displayDetails"
                        options={{
                          fields: [
                            'formatted_address',
                            'geometry',
                            'name',
                            'address_components',
                          ],
                          strictBounds: false,
                          types: ['address'],
                        }}
                        onPlaceSelected={(place) => {
                          if (place?.address_components?.length) {
                            setFieldValue(
                              'lat',
                              place?.geometry?.location?.lat(),
                            );
                            setFieldValue(
                              'lng',
                              place?.geometry?.location?.lng(),
                            );

                            let addressComponent,
                              addressComponentType,
                              rawzipcode,
                              rawcountry,
                              rawcity,
                              rawstate,
                              rawaddline;
                            for (
                              let i = 0;
                              i < place?.address_components?.length;
                              i++
                            ) {
                              addressComponent = place.address_components[i];
                              let j = 0;
                              addressComponentType = addressComponent.types[j];
                              if (addressComponentType === 'postal_code') {
                                rawzipcode = addressComponent.long_name
                                  ? addressComponent.long_name
                                  : '';
                              }
                              if (addressComponentType === 'country') {
                                rawcountry = addressComponent.long_name
                                  ? addressComponent.short_name
                                  : '';
                              }
                              if (
                                addressComponentType ===
                                'administrative_area_level_1'
                              ) {
                                rawstate = addressComponent.long_name
                                  ? addressComponent.short_name
                                  : '';
                              }
                              if (addressComponentType === 'locality') {
                                rawcity = addressComponent.long_name
                                  ? addressComponent.long_name
                                  : '';
                              }

                              if (
                                addressComponentType === 'street_number' ||
                                addressComponentType === 'route' ||
                                addressComponentType === 'neighborhood' ||
                                addressComponentType.includes('sublocality')
                              ) {
                                rawaddline = `${rawaddline ? rawaddline : ''}${addressComponent.long_name}, `;
                              }
                            }
                            setFieldValue('zipcode', rawzipcode);
                            setFieldValue('country', rawcountry);
                            setFieldValue('city', rawcity);
                            setFieldValue('state', rawstate);
                            setFieldValue('addressLine1', rawaddline);
                            setFieldValue(
                              'displayDetails',
                              (rawaddline ? rawaddline : '') +
                                (rawcity ? rawcity + ', ' : '') +
                                (rawstate ? rawstate + ', ' : '') +
                                (rawcountry ? rawcountry + ', ' : '') +
                                (rawzipcode ? rawzipcode : ''),
                            );

                            if (
                              place?.geometry?.location?.lat() &&
                              place?.geometry?.location?.lng()
                            ) {
                              fetchTimezone(
                                place?.geometry?.location?.lat(),
                                place?.geometry?.location?.lng(),
                                setFieldValue,
                              );
                            }
                          }
                        }}
                        value={values && values.displayDetails}
                        className={`form-control shadow-none autoaddress ${
                          touched.displayDetails &&
                          (errors.addressLine1 || !values?.displayDetails) &&
                          'is-invalid'
                        } ${values && values.addressLine1 && 'has-value'}`}
                      />
                      {!document.getElementById('displayDetails')?.value ? (
                        <span className="placeholder-wrapper required">
                          {constants.ADDRESS_FIELD}
                        </span>
                      ) : null}
                      {values && values.addressLine1 ? (
                        <span className={`reset-address-wrapper`}>
                          <IoClose
                            size={15}
                            onClick={() => {
                              setFieldValue('zipcode', '');
                              setFieldValue('country', '');
                              setFieldValue('city', '');
                              setFieldValue('state', '');
                              setFieldValue('addressLine1', '');
                              setFieldValue('displayDetails', '');
                              setFieldValue('timezone', '');
                              document.getElementById('displayDetails').value =
                                '';
                              setFieldTouched('displayDetails', true, false);
                            }}
                          />
                        </span>
                      ) : null}
                      {!document.getElementById('displayDetails')?.value ? (
                        <span className="placeholder-wrapper required">
                          {constants.ADDRESS_FIELD}
                        </span>
                      ) : null}
                      {values && values.addressLine1 ? (
                        <span className={`reset-address-wrapper`}>
                          <IoClose
                            size={15}
                            onClick={() => {
                              setFieldValue('zipcode', '');
                              setFieldValue('country', '');
                              setFieldValue('city', '');
                              setFieldValue('state', '');
                              setFieldValue('addressLine1', '');
                              setFieldValue('displayDetails', '');
                              setFieldValue('timezone', '');
                              document.getElementById('displayDetails').value =
                                '';
                              setFieldTouched('displayDetails', true, false);
                            }}
                          />
                        </span>
                      ) : null}
                    </div>
                    <ErrorMessage
                      component="div"
                      name="addressLine1"
                      className="error"
                    />
                    {touched.displayDetails &&
                      (!values?.addressLine1 ||
                        !values?.city ||
                        !values?.state ||
                        !values?.country ||
                        !values?.zipcode) && (
                        <div className="error">
                          {constants.ADDRESS_FIELD_ERROR_MESSAGE}
                        </div>
                      )}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col position-relative">
                    <div>
                      <LocationSelectField
                        isFromOrg={true}
                        name="timezone"
                        options={zoneTimeList}
                        label=""
                        defaultOptionLabel="Time Zone"
                        value={values?.timezone}
                        visibleLocHelperText={visibleLocHelperText}
                        locHelperText={locHelperText}
                        required={true}
                        labelclasses="org-modal-Timezone"
                        onChange={(event) =>
                          changeTimezoneValue(event, setFieldValue)
                        }
                      />
                    </div>

                    {visibleLocHelperText ? (
                      <div className="info-timezone">
                        <PiWarningCircleBold
                          color={getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--greyscale_56')}
                          size={16}
                        />
                        <div className="message-info">{locHelperText}</div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <CustomSelect
                  options={orgContact}
                  name="contactId"
                  key="contactId"
                  className="contactId-style"
                  selectedOptionId={values && values.contactId}
                  defaultOptionLabel={constants.LOCATION_CONTACT_DROPDOWN_LABEL}
                  avatarSize={24}
                  selectStyles={{
                    backgroundColor: '#ffffff',
                  }}
                  disabled={!getCustomerOrgPolicies()?.update_cust_org}
                  setFieldValue={setFieldValue}
                />

                <ManageAreas
                  childFunc={childFunc}
                  disabledState={false}
                  areasData={newLocaAreas}
                  updateAreaData={(areas) => setNewLocaAreas(areas)}
                  setShowRemoveAreaModal={setShowRemoveAreaModal}
                  setRemoveAreaName={setRemoveAreaName}
                  setRemoveAreaIndex={setRemoveAreaIndex}
                  removeAreaIndex={removeAreaIndex}
                  removeAreaName={removeAreaName}
                  showRemoveAreaModal={showRemoveAreaModal}
                  saveBtnStatus={(flag) => setBtnStatus(flag)}
                />

                {/* TODO: will update once get some backend value <label className="pt-2 mt-4">
                  <strong className='location-modal-heading'>{constants.BANDWIDTH_USAGE_BUDGET}</strong>

                  <TextBlock
                    fontWeight="400"
                    fontSize="0.875rem"
                    lineHeight="20px"
                    color={getComputedStyle(document.documentElement).getPropertyValue('--brand_primary')}
                  >
                    {constants.BANDWIDTH_HELPER_TEXT}
                  </TextBlock>
                </label> */}
                {/* TODO: will update once get some backend value <div>
                  <div className="d-flex align-items-center flex-row mt-3">
                    <input
                      className="form-control require shadow-none"
                      placeholder='(Optional)'
                      autoComplete="off"
                      name="bandwidth"
                      type="text"
                      value={values.bandwidth}
                      onChange={(event) => setFieldValue('bandwidth', event.target.value)}
                    />

                    <TextBlock
                      className="ml-2"
                      fontWeight="500"
                      fontSize="1rem"
                      lineHeight="20px"
                      color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')}
                    >
                      kbps
                    </TextBlock>
                  </div>
                  <div className="info-timezone mt-1">
                    <div className="message-info">
                      {constants.BANDWIDTH_MAX_CONTENT_HELPER_TEXT}
                    </div>
                  </div>
                </div> */}
                <Form.Group as={Row} className="mb-3 mt-5">
                  <Col className="text-end pe-2">
                    <PrimaryButton
                      className="btn btn-primary-outline"
                      type="button"
                      width="auto"
                      borderWidth="1.5px"
                      hoverBorderWidth="1.5px"
                      borderColor={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--error_64')}
                      hoverBorderColor={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--error_64')}
                      color={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--error_64')}
                      hoverColor={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--brand_white')}
                      hoverBackgroundColor={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--error_64')}
                      backgroundColor="transparent"
                      disabledBackgroundColor="transparent"
                      onClick={() => setShowRemoveLocationModal(true)}
                      disabled={!getCustomerOrgPolicies()?.delete_location}
                    >
                      {constants.EDIT_LOCATIONS_REMOVE_BUTTON_TITLE}
                    </PrimaryButton>
                    <PrimaryButton
                      className="ms-2 btn btn-primary btn-margin"
                      type="submit"
                      loader={isSubmitting}
                      height="44px"
                      width="auto"
                      fontSize="0.875rem"
                      disabled={
                        (!(
                          dirty &&
                          isValid &&
                          getCustomerOrgPolicies()?.update_account
                        ) &&
                          !isAreaUpdated) ||
                        newLocaAreas?.length === 0 ||
                        currentUserTimezone === undefined ||
                        !values?.timezone ||
                        !values?.zipcode ||
                        !document.getElementById('displayDetails')?.value ||
                        checkAddedAreas() ||
                        !btnStatus ||
                        isDuplicateLocation
                      }
                    >
                      {constants.EDIT_LOCATIONS_SUBMIT_BUTTON_TITLE}
                    </PrimaryButton>
                  </Col>
                </Form.Group>
              </Form>
              {/* Show error messages */}
              <SiteToast
                title={
                  variant === 'error'
                    ? constants.ERROR_TOAST_TITLE
                    : constants.SUCCESS_TOAST_TITLE
                }
                show={!!userMsg}
                body={userMsg}
                variant={variant}
                position="top-center"
              />
            </>
          )}
        </Formik>
      </div>
      {/* Remove location - confirmation modal */}
      <SiteModal
        modalTitle={
          deviceExists?.length > 0
            ? Utils.replaceStringValues(
                constants.EDIT_LOCATIONS_REMOVE_TITLE,
                '$locationName',
                locationsData?.locationName,
              )
            : Utils.replaceStringValues(
                constants.EDIT_LOCATIONS_REMOVE_MODAL_TITLE_MESSAGE,
                '$locationName',
                locationsData?.locationName,
              )
        }
        showModal={showRemoveLocationModal}
        hideModal={() => setShowRemoveLocationModal(false)}
        classes="remove-customer-location-modal"
        isFromLocation={true}
      >
        <TextBlock
          className="mt-1 mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--brand_primary',
          )}
        >
          {deviceExists?.length > 0
            ? constants.EDIT_LOCATIONS_MODAL_DEVICE_EXISTS_REMOVE_CONTENT
            : constants.EDIT_LOCATIONS_MODAL_REMOVE_CONTENT}
        </TextBlock>

        <Row>
          <Col className="text-end">
            <PrimaryButton
              className={`btn btn-primary-outline ${
                deviceExists?.length > 0 && 'd-none'
              }`}
              type="button"
              width="auto"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              borderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              backgroundColor="transparent"
              loader={showLoader}
              onClick={() => handleRemoveLocationClick()}
            >
              {constants.NEW_LOCATIONS_REMOVE_BUTTON_TITLE}
            </PrimaryButton>
            <PrimaryButton
              className="ms-2 btn btn-primary"
              type="button"
              height="44px"
              fontSize="0.875rem"
              width="auto"
              onClick={() => setShowRemoveLocationModal(false)}
            >
              {constants.EDIT_CUSTOMER_REMOVE_USER_CONFIRM_CANCEL_BUTTON_TITLE}
            </PrimaryButton>
          </Col>
        </Row>
        <SiteToast
          title={
            variant === 'error'
              ? constants.ERROR_TOAST_TITLE
              : constants.SUCCESS_TOAST_TITLE
          }
          show={!!userMsg}
          body={userMsg}
          variant={variant}
          position="top-center"
        />
      </SiteModal>
      {/* Remove location - confirmation modal */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.EDIT_LOCATIONS_REMOVE_AREA_MODAL_TITLE,
          '$areaName',
          removeAreaName,
        )}
        showModal={showRemoveAreaModal}
        hideModal={() => {
          setRemoveAreaIndex(null);
          setRemoveAreaName(null);
          setShowRemoveAreaModal(false);
        }}
        classes="remove-customer-location-modal"
      >
        <TextBlock
          className="mt-1 mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56',
          )}
        >
          {Utils.replaceStringValues(
            constants.EDIT_LOCATIONS_REMOVE_AREA_MODAL_REMOVE_CONTENT,
            '$areaName',
            removeAreaName,
          )}
        </TextBlock>

        <Row>
          <Col className="text-end">
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="auto"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              borderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              backgroundColor="transparent"
              onClick={() => handleRemoveAreaBtnClick()}
            >
              {constants.LOCATIONS_REMOVE_AREA_BUTTON_TITLE}
            </PrimaryButton>
            <PrimaryButton
              className="ms-2 btn btn-primary"
              type="button"
              height="44px"
              fontSize="0.875rem"
              width="auto"
              onClick={() => {
                setRemoveAreaIndex(null);
                setRemoveAreaName(null);
                setShowRemoveAreaModal(false);
              }}
            >
              {constants.EDIT_CUSTOMER_REMOVE_USER_CONFIRM_CANCEL_BUTTON_TITLE}
            </PrimaryButton>
          </Col>
        </Row>
      </SiteModal>
    </section>
  );
}

export default EditLocations;
