import { forwardRef } from 'react';
import { ErrorMessage, useField } from 'formik';
import { PiWarningCircleLight } from 'react-icons/pi';

const TextField = forwardRef((props, ref) => {
  const [field, meta] = useField(props);
  const { label, className, isColSm, hideWarningCircle, ...otherProps } = props;
  const colSmLabelClass = isColSm ? 'col-sm-4' : 'col-sm-3';
  const colSmFieldClass = isColSm ? 'col-sm-8' : 'col-sm-7';
  const hasError = meta.touched && meta.error;
  return (
    <div
      className={`${props.removebottommargin ? '' : 'mb-1'} ${
        props.removetopmargin ? '' : 'mt-1'
      } row ${className ? className : ''}`}
    >
      {label && (
        <label
          className={`${colSmLabelClass} form-label col-form-label`}
          htmlFor={field.name}
        >
          {label}
        </label>
      )}

      <div
        className={
          label
            ? `${colSmFieldClass} ${meta.touched && meta.error && 'is-invalid'} ${
                props.beforeinputicon || props.afterinputicon
                  ? 'input-with-icons'
                  : 'position-relative'
              }`
            : `col ${meta.touched && meta.error && 'is-invalid'} ${
                props.beforeinputicon || props.afterinputicon
                  ? 'input-with-icons'
                  : 'position-relative'
              }`
        }
      >
        {props.beforeinputicon && (
          <div className="icon-before-input">{props.beforeinputicon}</div>
        )}
        <div>
          <input
            className={`form-control require shadow-none ${
              meta.touched && meta.error && 'is-invalid'
            } ${field.value ? 'has-value' : ''} 
              ${props.isDuplicateLocation ? 'is-duplicate' : ''} ${props.inputClassName}`}
            {...field}
            {...otherProps}
            ref={ref}
            placeholder=""
            beforeinputicon=""
            afterinputicon=""
          />
          <span
            className={`placeholder-wrapper ${
              props.required ? 'required' : ''
            } ${meta.touched && meta.error && 'is-invalid'}`}
          >
            {props.placeholder}
          </span>
          {props.afterinputicon && (
            <div className="icon-after-input">{props.afterinputicon}</div>
          )}
        </div>

        {!props.hideError && hasError && (
          <div className="d-flex">
            {hideWarningCircle === true && (
              <PiWarningCircleLight
                className="event-icon mt-1"
                size={16}
                color="#de6868"
              />
            )}
            <ErrorMessage component="div" name={field.name} className="error" />
          </div>
        )}
      </div>
    </div>
  );
});
export default TextField;
