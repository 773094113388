import React from 'react';

import DetailsContextProvider from '../addDevice/DetailsContextProvider';
import DataContextProvider from '../addDevice/DataContextProvider';
import '../device.scss';
import '../deviceResponsive.scss';
import Devices from './Devices';
import './Devices.scss';

const Device = () => {
  return (
    <DetailsContextProvider>
      <DataContextProvider>
        <Devices />
      </DataContextProvider>
    </DetailsContextProvider>
  );
};

export default Device;
