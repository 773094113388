import store from '../../../../../store/Store';
import {
  getMQTTPeerConnections,
  removeMQTTPeerConnections,
  removeRemoteStreams,
  setLoadingStream,
  setMQTTPeerConnections,
  setRemoteStreams,
  setRemoteStreamsAudio,
} from '../../../../../store/reducers/StreamingReducer';
import * as mqtt from '../../../../../utils/connection/mqttConnection';
import Store from '../../../../../store/Store';

export const getLocalStreamLive = (
  publishTopic,
  serverDetails,
  accountId,
  deviceId,
  hubId,
  uuid,
  streamId,
) => {
  createMQTTPeerConnection(
    publishTopic,
    serverDetails,
    accountId,
    deviceId,
    hubId,
    uuid,
    streamId,
  );
};

const createMQTTPeerConnection = (
  publishTopic,
  serverDetails,
  accountId,
  deviceId,
  hubId,
  uuid,
  streamId,
) => {
  const { stun, turn } = serverDetails;
  const iceConfiguration = {
    iceServers: [
      {
        urls: `${stun.protocol}:${stun.host}:${stun.port}`,
      },
      {
        urls: `${turn.protocol}:${turn.host}:${turn.port}`,
        username: `${turn.userName}`,
        credential: `${turn.password}`,
      },
    ],
    // Todo: Delete later - keep it for now as connection is not finalyzed yet
    iceTransportPolicy: 'all',
    rtcpMuxPolicy: 'require',
    bundlePolicy: 'balanced',
  };
  let mqttPeerConnection;
  const mqttPeerConnections = store.getState(getMQTTPeerConnections)?.streaming
    ?.mqttPeerConnections;
  if (mqttPeerConnections?.[deviceId]) {
    mqttPeerConnection = mqttPeerConnections[deviceId];
  } else {
    mqttPeerConnection = new RTCPeerConnection(iceConfiguration);

    mqttPeerConnection.addTransceiver('video', {
      direction: 'recvonly',
    });

    // Will test once update on live streaming from firmware
    mqttPeerConnection.addTransceiver('audio', {
      direction: 'recvonly',
    });

    mqttPeerConnection.ontrack = ({ streams: [stream] }) => {
      if (stream.getAudioTracks().length) {
        store.dispatch(setRemoteStreamsAudio({ id: deviceId, audio: stream }));
      }
      if (stream.getVideoTracks().length) {
        store.dispatch(setRemoteStreams({ id: deviceId, stream: stream }));
      }
    };

    mqttPeerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        let candidate_data = JSON.parse(JSON.stringify(event.candidate));
        candidate_data.type = 'candidate';
        const time = Math.floor(new Date().getTime() / 1000.0);
        candidate_data.id = `${streamId}`;
        const payload = {
          tid: `${time}`,
          to: hubId,
          from: accountId,
          msg: {
            resource: `ch/${deviceId}/camera/streaming`,
            properties: { ...candidate_data },
          },
          publish: `d/notify/${accountId}/${uuid}`,
        };
        mqtt.sendWebRTCCandidate(publishTopic, payload);
      }
    };

    mqttPeerConnection.onconnectionstatechange = (event) => {
      // TODO: delete later
      if (mqttPeerConnection.connectionState === 'connected') {
        // TODO: delete later
        console.log('succesfully connected with other peer');
        Store.dispatch(setLoadingStream(false));
      }
      if (mqttPeerConnection.connectionState === 'failed') {
        getLocalStreamLive(
          publishTopic,
          serverDetails,
          accountId,
          deviceId,
          hubId,
          uuid,
          streamId,
        );
      }
    };

    store.dispatch(
      setMQTTPeerConnections({ id: deviceId, client: mqttPeerConnection }),
    );

    setTimeout(() => {
      sendOffer(publishTopic, accountId, deviceId, hubId, uuid, streamId);
    }, 100);
  }
};

export const sendOffer = async (
  publishTopic,
  accountId,
  deviceId,
  hubId,
  uuid,
  updatedStreamId,
) => {
  //TODO Delete later
  const mqttPeerConnections = store.getState(getMQTTPeerConnections)?.streaming
    ?.mqttPeerConnections;
  const mqttPeerConnection = mqttPeerConnections?.[deviceId];
  const offer = await mqttPeerConnection?.createOffer({
    offerToReceiveVideo: true,
    offerToReceiveAudio: true,
  });
  await mqttPeerConnection?.setLocalDescription(offer);
  const time = Math.floor(new Date().getTime() / 1000.0);
  const payload = {
    tid: `${time}`,
    to: hubId,
    from: accountId,
    msg: {
      resource: `ch/${deviceId}/camera/streaming`,
      properties: {
        id: `${updatedStreamId}`,
        type: 'offer',
        sdp: offer.sdp,
      },
    },
    publish: `d/notify/${accountId}/${uuid}`,
  };
  mqtt.sendWebRTCOffer(publishTopic, payload);
};

// export const handleOffer = async (data) => {
//   await mqttPeerConnection[deviceId].setRemoteDescription(data.offer);
// };

export const handleLeaveCall = async (deviceId) => {
  const mqttPeerConnections = store.getState(getMQTTPeerConnections)?.streaming
    ?.mqttPeerConnections;
  let mqttPeerConnection = mqttPeerConnections?.[deviceId];
  if (mqttPeerConnection && mqttPeerConnection?.close) {
    mqttPeerConnection.close();
    mqttPeerConnection.onicecandidate = null;
    mqttPeerConnection.ontrack = null;
    mqttPeerConnection.onconnectionstatechange = null;
    mqttPeerConnection = null;
  }
  store.dispatch(removeRemoteStreams(deviceId));
  store.dispatch(removeMQTTPeerConnections(deviceId));
};
