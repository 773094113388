import React, { useState, useEffect } from 'react';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import {
  Header,
  Footer,
  TextBlock,
  PrimaryButton,
  SiteSpinner,
} from '../components/common';
import { TextField } from '../components/forms';
import { constants } from '../helpers';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { RxCrossCircled, RxCheckCircled } from 'react-icons/rx';
import axios from 'axios';
import OtpInputField from './OtpInputField';
import { useAppsStore } from '../store/AppsStore';
import './Auth.scss';

// Schema for yup
const validationSchema = Yup.object().shape({
  invitationCode: Yup.string().test(
    'len',
    constants.INVITATION_ID_ERROR_MESSAGE,
    (val) => val && val.length === 6,
  ),
  password: Yup.string()
    .required(constants.PASSWORD_ERROR_MESSAGE)
    .matches(
      /^(?=.*[A-Z])(?=.*[!@#$&^*%:;,])(?=.*[0-9])(?=.*[a-z]).{8,30}/,
      ' ',
    ),
  confirmPassword: Yup.string()
    .required(constants.CONFIRM_PASSWORD_ERROR_MESSAGE)
    .oneOf(
      [Yup.ref('password'), null],
      constants.CONFIRM_PASSWORD_NOT_MATCH_ERROR_MESSAGE,
    ),
});
const validationSchemaLdapEnabled = Yup.object().shape({
  invitationCode: Yup.string().required(constants.INVITATION_ID_ERROR_MESSAGE),
});
function AccountVerify() {
  const [show, setShow] = useState(false);
  const [userMsg, setUserMsg] = useState({ varient: '', msg: '' });
  const [resendUserMsg, setResendUserMsg] = useState({ varient: '', msg: '' });
  const [resendCodeLoader, setResendCodeLoader] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [cnfPasswordShown, setCnfPasswordShown] = useState(false);
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const invId = query.get('invId');
  const ldapEnabled = query.get('ldapEnabled')
    ? query.get('ldapEnabled')
    : 'false';
  const getHelpData = useAppsStore((state) => state.getHelpData);
  const helpData = useAppsStore((state) => state.helpData);

  useEffect(() => {
    getHelpData(`partner/help`);
  }, []);

  /* Toggle for password show/hide */
  const setPasswordShowHide = () => {
    setPasswordShown(!passwordShown);
  };

  /* Toggle for confirm password show/hide */
  const setCnfPasswordShowHide = () => {
    setCnfPasswordShown(!cnfPasswordShown);
  };

  const testCapsRegex = (val) => {
    const regex = new RegExp('[A-Z]+', 'g');
    return regex.test(val);
  };

  const testLowerRegex = (val) => {
    const regex = new RegExp('[a-z]+', 'g');
    return regex.test(val);
  };

  const testDigitRegex = (val) => {
    const regex = new RegExp('[0-9]+', 'g');
    return regex.test(val);
  };

  const testSpecialCharRegex = (val) => {
    const regex = new RegExp('[#$%^&*@!]+', 'gm');
    return regex.test(val);
  };

  /* To Resend a new Invitation code. */
  const resendAccountActivationCode = (event) => {
    event.preventDefault();
    setResendCodeLoader(true);
    if (invId) {
      delete axios.defaults.headers.common['Authorization'];
      axios
        .post(`partner/resendActivation`, { invitationId: invId })
        .then((res) => {
          let response = res.data;
          if (response?.meta?.code === 200) {
            setResendUserMsg({
              varient: 'success',
              msg: constants.ACCOUNT_VERIFY_SUCCESS_MESSAGE,
            });
          } else {
            setResendUserMsg({
              varient: 'danger',
              msg: response?.meta?.userMsg,
            });
          }
          setResendCodeLoader(false);
        })
        .catch(function (error) {
          setResendUserMsg({ varient: 'danger', msg: error.message });
          setResendCodeLoader(false);
        });
    } else {
      setResendUserMsg({
        varient: 'danger',
        msg: 'Unable to process the request!',
      });
      setResendCodeLoader(false);
    }
  };

  return (
    <div className="account-verify">
      <Header isAccountScreen={true} showNavigation="false" />

      <section className="main-page">
        <Container>
          <Row className="justify-content-md-center">
            <Col xs lg="8">
              <Container className="main-page-content mt-5 text-center">
                <Row className="text-center">
                  <Col className="remove-padding-responsive">
                    <div className="card main-card remove-padding-responsive">
                      <div className="card-body text-center">
                        <div className="text-center mb-1">
                          <TextBlock
                            fontWeight="400"
                            fontSize="1.25rem"
                            lineHeight="28px"
                            mobileFontSize="1rem"
                            mobileLineHeight="24px"
                            color={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--greyscale_08')}
                          >
                            {constants.ACCOUNT_VERIFY_PAGE_TITLE}
                          </TextBlock>
                        </div>

                        {invId && (
                          <Formik
                            enableReinitialize
                            initialValues={{
                              invitationId: invId,
                              password: '',
                              confirmPassword: '',
                              invitationCode: '',
                            }}
                            // Hooks up our validationSchema to Formik
                            validationSchema={
                              ldapEnabled !== 'false'
                                ? validationSchemaLdapEnabled
                                : validationSchema
                            }
                            onSubmit={(values, { setSubmitting }) => {
                              // When button submits form and form is in the process of submitting, submit button is disabled
                              setSubmitting(true);
                              if (ldapEnabled !== 'false') {
                                delete values.confirmPassword;
                                delete values.password;
                              }
                              delete axios.defaults.headers.common[
                                'Authorization'
                              ];
                              // Simulate submitting to database, shows us values submitted, resets form
                              axios
                                .post(`partner/accounts/verify`, values)
                                .then((res) => {
                                  let response = res.data;
                                  if (response?.meta?.code === 200) {
                                    navigate('/', {
                                      state: {
                                        status: 'success',
                                        msg: constants.ACCOUNT_VERIFY_LOGIN_PAGE_SUCCESS_MESSAGE,
                                      },
                                    });
                                    setShow(true);
                                    setSubmitting(false);
                                  } else {
                                    setUserMsg({
                                      varient: 'danger',
                                      msg: response?.meta?.userMsg,
                                    });
                                    setShow(true);
                                    setSubmitting(false);
                                  }
                                });
                            }}
                          >
                            {({
                              values,
                              setFieldValue,
                              errors,
                              handleSubmit,
                              isValid,
                              dirty,
                              isSubmitting,
                              setErrors,
                            }) => (
                              <Form
                                onSubmit={handleSubmit}
                                className="text-start"
                              >
                                <Row className="text-center mt-4">
                                  <Col sm={12}>
                                    <Form.Label>
                                      <TextBlock
                                        fontWeight="700"
                                        fontSize="1.25rem"
                                        lineHeight="28px"
                                        mobileFontSize="1.25rem"
                                        mobileLineHeight="28px"
                                        mobileFontWeight="700"
                                        color={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--greyscale_08')}
                                      >
                                        {
                                          constants.ACCOUNT_VERIFY_ACTIVATION_CODE_LABEL
                                        }
                                      </TextBlock>
                                    </Form.Label>
                                  </Col>
                                </Row>

                                <Form.Group
                                  as={Row}
                                  className="mb-3 mt-3 text-center account-verify-input-section"
                                  controlId="password"
                                >
                                  <Col sm={12}>
                                    {values.invitationCode &&
                                    errors.invitationCode ? (
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="mb-3"
                                      >
                                        {errors.invitationCode}
                                      </Form.Control.Feedback>
                                    ) : null}
                                    <OtpInputField
                                      errorStyle={
                                        values.invitationCode &&
                                        errors.invitationCode &&
                                        'is-invalid'
                                      }
                                      numOfInputs={6}
                                      getResultData={(otp) => {
                                        if (otp) {
                                          setFieldValue('invitationCode', otp);
                                          setErrors({
                                            invitationCode: '',
                                          });
                                        } else {
                                          setErrors({
                                            invitationCode:
                                              constants.INVITATION_ID_ERROR_MESSAGE,
                                          });
                                        }
                                      }}
                                    />
                                    <TextBlock
                                      className="mt-2"
                                      fontWeight="500"
                                      fontSize="0.875rem"
                                      lineHeight="20px"
                                      color={getComputedStyle(
                                        document.documentElement,
                                      ).getPropertyValue('--greyscale_56')}
                                    >
                                      {
                                        constants.ACCOUNT_VERIFY_RESENDCODE_HEADING
                                      }
                                      <Link
                                        to="#"
                                        className="resend-code-link"
                                        onClick={resendAccountActivationCode}
                                      >
                                        {
                                          constants.ACCOUNT_VERIFY_RESENDCODE_LINK_TITLE
                                        }
                                        {resendCodeLoader && (
                                          <SiteSpinner
                                            height="15px"
                                            width="15px"
                                          />
                                        )}
                                      </Link>
                                    </TextBlock>

                                    <div
                                      className="mt-3"
                                      style={{
                                        fontSize: '0.875rem',
                                        display: resendUserMsg.msg
                                          ? 'inline-block'
                                          : 'none',
                                        color:
                                          resendUserMsg.varient === 'success'
                                            ? getComputedStyle(
                                                document.documentElement,
                                              ).getPropertyValue('--success_48')
                                            : getComputedStyle(
                                                document.documentElement,
                                              ).getPropertyValue('--error_64'),
                                        fontWeight: 600,
                                      }}
                                    >
                                      {resendUserMsg.varient === 'success' ? (
                                        <RxCheckCircled
                                          strokeWidth="1.5"
                                          size={12}
                                        />
                                      ) : (
                                        <RxCrossCircled
                                          strokeWidth="1.5"
                                          size={12}
                                        />
                                      )}{' '}
                                      {resendUserMsg.msg}
                                    </div>
                                  </Col>
                                </Form.Group>

                                {ldapEnabled === 'false' ? (
                                  <>
                                    <Row className="text-center mt-4 mb-3">
                                      <Col sm={12}>
                                        <Form.Label>
                                          <TextBlock
                                            fontWeight="700"
                                            fontSize="1.5rem"
                                            lineHeight="32px"
                                            mobileFontSize="1.25rem"
                                            mobileLineHeight="28px"
                                            mobileFontWeight="700"
                                            color={getComputedStyle(
                                              document.documentElement,
                                            ).getPropertyValue(
                                              '--greyscale_08',
                                            )}
                                          >
                                            {
                                              constants.ACCOUNT_VERIFY_SETUP_PASSWORD_LABEL
                                            }
                                          </TextBlock>
                                        </Form.Label>
                                      </Col>
                                    </Row>

                                    <TextField
                                      beforeinputicon={<HiOutlineLockClosed />}
                                      afterinputicon={
                                        <div onClick={setPasswordShowHide}>
                                          {!passwordShown ? (
                                            <AiOutlineEyeInvisible />
                                          ) : (
                                            <AiOutlineEye />
                                          )}
                                        </div>
                                      }
                                      required={true}
                                      label=""
                                      placeholder={
                                        constants.CREATE_PASSWORD_FIELD
                                      }
                                      name="password"
                                      type={passwordShown ? 'text' : 'password'}
                                    />

                                    <Row
                                      style={{
                                        fontSize: '0.75rem',
                                        display:
                                          values.password.length > 0
                                            ? 'inline-block'
                                            : 'none',
                                      }}
                                    >
                                      <Col sm={12} className="mb-2">
                                        <TextBlock
                                          fontWeight="600"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          mobileFontSize="0.875rem"
                                          mobileLineHeight="20px"
                                          mobileFontWeight="600"
                                          color={getComputedStyle(
                                            document.documentElement,
                                          ).getPropertyValue('--brand_black')}
                                        >
                                          {
                                            constants.ACCOUNT_VERIFY_PASSWORD_REQUIREMENTS_LABEL
                                          }
                                        </TextBlock>
                                      </Col>
                                      <Col sm={12} className="mb-2">
                                        {values.password.length < 8 ? (
                                          <RxCrossCircled
                                            strokeWidth="1.5"
                                            size={12}
                                            className="checked-valid-false"
                                          />
                                        ) : (
                                          <RxCheckCircled
                                            strokeWidth="1.5"
                                            size={12}
                                            className="checked-valid-true"
                                          />
                                        )}
                                        {
                                          constants.ACCOUNT_VERIFY_ATLEAST_EIGHT_CHARS
                                        }
                                      </Col>
                                      <Col sm={12} className="mb-2">
                                        {testCapsRegex(values.password) ? (
                                          <RxCheckCircled
                                            strokeWidth="1.5"
                                            size={12}
                                            className="checked-valid-true"
                                          />
                                        ) : (
                                          <RxCrossCircled
                                            strokeWidth="1.5"
                                            size={12}
                                            className="checked-valid-false"
                                          />
                                        )}
                                        {
                                          constants.ACCOUNT_VERIFY_ATLEAST_ONE_UPPERCASE
                                        }
                                      </Col>
                                      <Col sm={12} className="mb-2">
                                        {testLowerRegex(values.password) ? (
                                          <RxCheckCircled
                                            strokeWidth="1.5"
                                            size={12}
                                            className="checked-valid-true"
                                          />
                                        ) : (
                                          <RxCrossCircled
                                            strokeWidth="1.5"
                                            size={12}
                                            className="checked-valid-false"
                                          />
                                        )}
                                        {
                                          constants.ACCOUNT_VERIFY_ATLEAST_ONE_LOWERCASE
                                        }
                                      </Col>
                                      <Col sm={12} className="mb-2">
                                        {testDigitRegex(values.password) ? (
                                          <RxCheckCircled
                                            strokeWidth="1.5"
                                            size={12}
                                            className="checked-valid-true"
                                          />
                                        ) : (
                                          <RxCrossCircled
                                            strokeWidth="1.5"
                                            size={12}
                                            className="checked-valid-false"
                                          />
                                        )}
                                        {
                                          constants.ACCOUNT_VERIFY_ATLEAST_ONE_NUMBER
                                        }
                                      </Col>
                                      <Col sm={12} className="mb-3">
                                        {testSpecialCharRegex(
                                          values.password,
                                        ) ? (
                                          <RxCheckCircled
                                            strokeWidth="1.5"
                                            size={12}
                                            className="checked-valid-true"
                                          />
                                        ) : (
                                          <RxCrossCircled
                                            strokeWidth="1.5"
                                            size={12}
                                            className="checked-valid-false"
                                          />
                                        )}
                                        {
                                          constants.ACCOUNT_VERIFY_ATLEAST_ONE_SPECIAL_CHAR
                                        }
                                      </Col>
                                    </Row>

                                    <TextField
                                      beforeinputicon={<HiOutlineLockClosed />}
                                      afterinputicon={
                                        <div onClick={setCnfPasswordShowHide}>
                                          {!cnfPasswordShown ? (
                                            <AiOutlineEyeInvisible />
                                          ) : (
                                            <AiOutlineEye />
                                          )}
                                        </div>
                                      }
                                      required={true}
                                      label=""
                                      placeholder={
                                        constants.CONFIRM_PASSWORD_FIELD
                                      }
                                      name="confirmPassword"
                                      type={
                                        cnfPasswordShown ? 'text' : 'password'
                                      }
                                    />
                                  </>
                                ) : null}

                                <Form.Group as={Row} className="">
                                  <Col>
                                    {show && (
                                      <Alert
                                        variant={userMsg.varient}
                                        className="text-start"
                                        onClose={() => setShow(false)}
                                        dismissible
                                      >
                                        {userMsg.msg}
                                      </Alert>
                                    )}
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3 mt-3">
                                  <Col>
                                    <PrimaryButton
                                      className="btn btn-primary w-100"
                                      type="submit"
                                      disabled={
                                        !(dirty && isValid) ||
                                        values.invitationCode?.length < 6
                                      }
                                      loader={isSubmitting}
                                      height="56px"
                                    >
                                      {constants.ACCOUNT_VERIFY_SUBMIT_BUTTON}
                                    </PrimaryButton>
                                  </Col>
                                </Form.Group>

                                <Form.Group
                                  as={Row}
                                  className="mb-3 mt-3 text-center"
                                >
                                  <Col>
                                    <p
                                      className="help-link"
                                      onClick={() =>
                                        window.open(
                                          helpData?.portalCommon,
                                          '_blank',
                                        )
                                      }
                                    >
                                      {constants.ACCOUNT_VERIFY_HELP_LINK_TITLE}
                                    </p>
                                  </Col>
                                </Form.Group>
                              </Form>
                            )}
                          </Formik>
                        )}
                      </div>
                    </div>

                    {!invId && (
                      <Alert variant="danger" className="text-start">
                        {constants.ACCOUNT_VERIFY_ERROR_MESSAGE}
                      </Alert>
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer
        backgroundColor={getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--primary_96')}
        centeredLinks="true"
      />
    </div>
  );
}

export default AccountVerify;
